import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'machine'

const prefix = convertNameToPrefix(name)

const endpoint = (data = '') => `${prefix}/${data}`

export const fetchMachines = createThunk(`${prefix}/fetch`, () => endpoint(), 'get', false);

export const createMachine = createThunk(`${prefix}/create`, () => endpoint(), 'post', true);

export const updateMachine = createThunk(`${prefix}/update`, (data) => endpoint(data.id), 'post', true);

export const deleteMachine = createThunk(`${prefix}/delete`, (id) => endpoint(id), 'delete', true);

export const fetchVendorMachines = createThunk(`${prefix}/vendor/fetch`, (id) => `${id}/machines`, 'get', false);

export const saveRefill = createThunk(`${prefix}/refill/save`, () => `refill`, 'post', true);

export const updatePrice = createThunk(`${prefix}/price/update`, () => `price`, 'post', true);


export const machine = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        reducerHandlers(builder, fetchMachines);
        reducerHandlers(builder, createMachine);
        reducerHandlers(builder, updateMachine);
        reducerHandlers(builder, deleteMachine);
        reducerHandlers(builder, fetchVendorMachines);
        reducerHandlers(builder, saveRefill);
        reducerHandlers(builder, updatePrice);
    },
});

export default machine.reducer;





