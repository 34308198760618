import React, { useEffect } from "react";
import TopSection from "app/components/common/dashboard/TopSection";
import MachineSales from "app/components/common/dashboard/MachineSales";
import { fetchVendorDashboard } from "features/DashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUS, Loading } from "services/CommonService";


const Dashboard = () => {

    const dispatch = useDispatch()

    const { data: auth } = useSelector(state => state.auth)

    useEffect(() => {
        dispatch(fetchVendorDashboard(auth?.user?.id))
    }, [dispatch, auth.user?.id])

    const { data, status } = useSelector(state => state.dashboard)


    return (
        <React.Fragment>
            <TopSection data={data[0]} />
            <MachineSales data={data[0]} />
            {status === STATUS.LOADING && <Loading />}
        </React.Fragment>
    );
}

export default Dashboard