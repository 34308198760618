import React from "react"
import adminAside from "./AdminAside"
import vendorAside from "./VendorAside"
import { hideOverlay } from "./AsideDisplayFn"
import { useSelector } from "react-redux"
import { IMAGE_PATH } from "config"


const Aside = () => {

    const { data: auth } = useSelector((state) => state.auth)

    return (
        <React.Fragment>
            <aside className="sidebar-nav-wrapper">
                <div className="navbar-logo image-logo">
                    <img src={`${IMAGE_PATH}/logo.png`} alt="Logo" style={{ width: '60%' }} />
                </div>
                <nav className="sidebar-nav">
                    <ul>
                        {auth.role === 'admin' && adminAside()}
                        {auth.role === 'vendor' && vendorAside()}
                        {/* {token && type === 'staff' && staffRoutes}
                        {token && type === 'customer' && customerRoutes} */}
                    </ul>
                </nav>
            </aside>
            <div onClick={() => hideOverlay()} className="overlay"></div>
        </React.Fragment>
    )
}

export default Aside