import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const PageNotFound = () => {

    const { data: auth } = useSelector((state) => state.auth)

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <div className="card" style={{ background: 'transparent', border: 'none' }}>
                <div className="card-body text-center">
                    <h2>404 ! Page Not Found</h2>
                    <p className='mb-2'>The page you are looking for does not exist.</p>

                    {auth?.role ? <Link to={`/${auth.role}`} > Go to dashboard </Link> : <Link to={`/`} > Go to home </Link>}

                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
