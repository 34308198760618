import { toast } from "react-toastify";
import { STATUS, transformErrorData } from "./CommonService";
import axiosInstance from "axiosInstance";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRole } from "./AuthService";
import { API_URL } from "config";

export const createThunk = (actionType, urlBuilder, method, showToast) =>
    createAsyncThunk(
        actionType,
        async (arg) => makeApiRequest(showToast, urlBuilder(arg), method, arg)
    );

export const reducerHandlers = (builder, asyncAction) => {
    builder.addCase(asyncAction.pending, (state) => {
        state.status = STATUS.LOADING;
    });

    builder.addCase(asyncAction.fulfilled, (state, action) => {
        fulfilledReducer(state, action);
    });

    builder.addCase(asyncAction.rejected, (state, action) => {
        rejectedReducer(state, action);
    });
};

export const convertNameToPrefix = (str) => {
    return str.split(/(?=[A-Z])/).join('/').toLowerCase();
};

const responseWithToast = (response, showToast) => {
    return { ...response.data, showToast }
}

const url = (endpoint) => {
    const role = getRole();
    const roleUrl = role ? `${API_URL}/${role}/${endpoint}` : `${API_URL}/${endpoint}`;
    return roleUrl.replace(/\/$/, ''); // Remove trailing slash
};

const makeApiRequest = async (showToast, urlEndpoint, method, data) => {
    try {
        const response = await axiosInstance[method](url(urlEndpoint), data);
        return responseWithToast(response, showToast);
    } catch (error) {
        const transformedError = error && JSON.stringify(transformErrorData(error.response));
        throw new Error(transformedError);
    }
};

const fulfilledReducer = (state, action) => {
    if (action.payload.data === 'Unauthorized') {
        state.status = STATUS.ERROR;
        state.data = [];
        state.validationErrors = {
            message: 'Please provide correct email or password',
            errors: {
                unauthorized: ['Please provide correct email or password'],
            },
        };
    } else if (action.payload.success && action.payload.data !== 'Unauthorized') {
        state.status = STATUS.IDLE
        state.data = action.payload.data
        // state.validationErrors = {}
        if (action.payload.showToast && action.payload.message) {
            toast.success(action.payload.message)
        }
    } else {
        state.status = STATUS.ERROR;
        toast.error('Unknown error.');
    }
};


const rejectedReducer = (state, action) => {

    state.status = STATUS.ERROR;

    if (action.error.message) {
        const errorData = JSON.parse(action.error.message);

        if (errorData.statusCode === 401) {

            window.location.href = '/logout';
        }

        state.validationErrors = errorData.validationErrors || null;

        if (errorData.validationErrors) {
            toast.error('Check input again.');
        } else {
            // For other status codes
            toast.error(errorData.message || 'Unknown error.');
        }
    } else {
        toast.error('Unknown error.');
    }
};


export const initialState = {
    data: [],
    status: STATUS.IDLE
}


