import { getRole } from "services/AuthService";

// change local to production if the app is in production
// const ENV = 'local'
// const ENV = 'production'
export const ENV = 'demo'

const LOCAL_APP_URL = `http://127.0.0.1:8000`

const PRODUCTION_APP_URL = `https://bvend.xyz`

const LOCAL_API_URL = `${LOCAL_APP_URL}/api`

const PRODUCTION_API_URL = `${PRODUCTION_APP_URL}/api`


let API_URL = ENV === 'local' ? LOCAL_API_URL : PRODUCTION_API_URL;

const role = getRole();

if (role) API_URL += `/${role}`;

const IMAGE_PATH = ENV === 'local'
    ? `${LOCAL_APP_URL}/storage/uploads/images`
    : `${PRODUCTION_APP_URL}/storage/uploads/images`

export { API_URL, LOCAL_API_URL, PRODUCTION_API_URL, IMAGE_PATH }


export const MACHINE_TYPE_ID = {
    store: 1,
    // box: 2,
    // wash: 3,
    // dry: 4,
    // charge: 5,
}


