
import React from "react";
import { Route } from "react-router-dom";
import AdminDashboard from "app/components/pages/admin_panel/dashboard";
import ProductList from "app/components/pages/admin_panel/product/ProductList";
import ProductCreate from "app/components/pages/admin_panel/product/ProductCreate";
import ProductCategoryList from "app/components/pages/admin_panel/product_category/ProductCategoryList";
import ProductCategoryCreate from "app/components/pages/admin_panel/product_category/ProductCategoryCreate";
import VendorList from "app/components/pages/admin_panel/vendor/VendorList";
import VendorCreate from "app/components/pages/admin_panel/vendor/VendorCreate";
import MachineTypeList from "app/components/pages/admin_panel/machine_type/MachineTypeList";
import MachineTypeCreate from "app/components/pages/admin_panel/machine_type/MachineTypeCreate";
import MachineCreate from "app/components/pages/admin_panel/machine/MachineCreate";
import AssignProductList from "app/components/pages/admin_panel/machine/AssignProduct";
import Logout from "app/components/common/Logout";
import PageNotFound from "app/components/common/PageNotFound";
import TransactionList from "app/components/common/transaction/TransactionList"
import MachineProductList from "app/components/common/machine/MachineProductList"
import MachineDetails from "app/components/common/machine/MachineDetails"
import AdminMachineList from "app/components/pages/admin_panel/machine/AdminMachineList";
import VendorDetails from "app/components/pages/admin_panel/vendor/VendorDetails";

const adminRoutes = () => (
    <React.Fragment>
        <Route path="/admin" element={<AdminDashboard />} />

        <Route path={'/machines'} element={<AdminMachineList />} />
        <Route path={'/products/:machineId'} element={<MachineProductList />} />
        <Route path={'/transactions/:machineId'} element={<TransactionList />} />
        <Route path={'/products'} element={<ProductList />} />
        <Route path={'/product/create'} element={<ProductCreate />} />
        <Route path={'/product/category'} element={<ProductCategoryList />} />
        <Route path={'/product/category/create'} element={<ProductCategoryCreate />} />
        <Route path={'admin/transactions'} element={<AdminMachineList />} />
        <Route path={'/machine/:machineId'} element={<MachineDetails />} />
        <Route path={'/products/:machineId'} element={<MachineProductList />} />
        <Route path={'/machine/create'} element={<MachineCreate />} />
        <Route path={'/product/assign/:machineId'} element={<AssignProductList />} />
        <Route path={'/machine/type'} element={<MachineTypeList />} />
        <Route path={'/machine/type/create'} element={<MachineTypeCreate />} />
        <Route path={'/vendors'} element={<VendorList />} />
        <Route path={'/vendor/:vendorId'} element={<VendorDetails />} />
        <Route path={'/vendor/create'} element={<VendorCreate />} />
        {/* <Route path={'/vendor/requests'} element={<VendorRequest />} /> */}

        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<PageNotFound />} />
    </React.Fragment>
)

export default adminRoutes