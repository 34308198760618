import React from 'react';
import { Button } from 'react-bootstrap';
import { reset } from "features/AuthSlice"
import { useNavigate } from "react-router-dom"
import { persistor } from "app/store";
import { useDispatch } from "react-redux"


const Logout = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const handleLogout = async () => {
        await dispatch(reset())
        await persistor.purge()
        navigate('/')
    }

    return (
        <div className="logout-container card-style-2">
            <div className="logout-message">
                <h2 className='fw-normal'>Session Expired !!</h2>
            </div>

            <Button className='main-btn danger-btn-outline rounded-full btn-hover pt-2 pb-2' onClick={handleLogout}>Login again</Button>

        </div>
    );
};

export default Logout;
