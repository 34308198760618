// Module: Machine

import * as yup from "yup";

const CreateFormValidationRules = yup.object().shape({
    machine_type_id: yup.number().nullable().required('*Machine type is required'),

    no_of_rows: yup.number('*No of rows is required')
        .positive('Enter positive number')
        .when(['machine_type_id'], {
            is: (machineTypeId) => machineTypeId === 1 || machineTypeId === 2,
            then: yup.number('*No of rows is required').required('*No of rows is required'),
        }),

    no_of_columns: yup.number('*No of columns is required')
        .positive('Enter positive number')
        .when(['machine_type_id'], {
            is: (machineTypeId) => machineTypeId === 1 || machineTypeId === 2,
            then: yup.number('*No of columns is required').required('*No of columns is required'),
        }),

    capacity: yup.number('*Capacity is required')
        .positive('Enter positive number')
        .max(10, '*Maximum capacity 10'),

    vendor_id: yup.number().nullable().required('*Vendor is required'),
});

const UpdateFormValidationRules = yup.object({
    // machine_types_id: yup.string().required('*Type is required'),
    no_of_rows: yup.number(),
    no_of_columns: yup.number(),
    capacity: yup.number(),
    // locks_per_column: yup.number()
})


export { CreateFormValidationRules, UpdateFormValidationRules }