import product from "features/ProductSlice";
import productCategory from "features/ProductCategorySlice";
import vendor from "features/VendorSlice";
import machine from "features/MachineSlice";
import machineType from "features/MachineTypeSlice";
import orderData from "features/OrderDataSlice";
import auth from "features/AuthSlice";
import { combineReducers } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage"
import customer from "features/CustomerSlice";
import transaction from "features/TransactionSlice";
import customerPayment from "features/CustomerPaymentSlice";
import dashboard from "features/DashboardSlice";


const appReducer = combineReducers({
    auth,
    product,
    productCategory,
    vendor,
    machine,
    machineType,
    transaction,
    customer,
    orderData,
    customerPayment,
    dashboard
})
const rootReducer = (state, action) => {

    if (action.type === "persist/PURGE") {
        storage.removeItem('persist:root')
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export default rootReducer;