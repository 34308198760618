import { IMAGE_PATH } from "config";
import React from "react";
import { ThreeDots } from "react-loader-spinner";

export const getFileName = (fullPath) => {
    return fullPath.replace(/^.*[\\/]/, '');
}

export const STATUS = Object.freeze({
    IDLE: 'success',
    ERROR: 'failed',
    LOADING: 'loading'
})


export const Loading = () => (
    <React.Fragment>
        <div className="loading-overlay">
            <ThreeDots
                height="40"
                width="40"
                radius="9"
                color="#cacaca"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    </React.Fragment>
)

export const Empty = ({ props }) => <p>{props}</p>

export const Wrong = () => <h4>Something went wrong...</h4>



export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        timeZoneName: 'short'
    };

    return date.toLocaleDateString('en-US', options);
}

export const imageFromSource = endpoint => {
    return `${IMAGE_PATH}/${endpoint}`;
}

export const imageNotFound = () => {
    return `${IMAGE_PATH}/placeholder/not_found.png`;
}

export const avatar = () => {
    return `${IMAGE_PATH}/placeholder/avatar.jpg`;
}


export const userRoles = [
    {
        id: 'admin',
        name: 'Admin'
    },
    {
        id: 'vendor',
        name: 'Vendor'
    }
]

const getErrorMessage = (statusCode) => {
    const customMessages = [
        { code: 401, message: 'Invalid email or password.' },
        { code: 403, message: 'Access Denied: You do not have permission to view this content.' },
        { code: 404, message: 'Page Not Found: The requested page does not exist.' },
        { code: 422, message: 'Validation Error: Please check your input and try again.' },
        { code: 500, message: 'Server Error: Please try again later.' },
        { code: -1, message: 'An unexpected error occurred. Please try again.' }, // Default message
    ];

    const customMessage = customMessages.find((msg) => msg.code === statusCode);
    return customMessage ? customMessage.message : customMessages.find((msg) => msg.code === -1).message;
};


// Functions for each status code
const handle401Error = () => ({
    message: getErrorMessage(401),
    statusCode: 401,
});

const handle403Error = () => ({
    message: getErrorMessage(403),
    statusCode: 403,
});

const handle404Error = () => ({
    message: getErrorMessage(404),
    statusCode: 404,
});

const handle500Error = () => ({
    message: getErrorMessage(500),
    statusCode: 500,
});

const handle422Error = (errors) => {
    return {
        message: getErrorMessage(422),
        statusCode: 422,
        validationErrors: errors.data ? errors.data : null,
    };
};


// Object mapping status codes to handler functions
const errorHandlers = {
    401: handle401Error,
    403: handle403Error,
    404: handle404Error,
    422: handle422Error,
    500: handle500Error,
};

// Composite function that uses the handler based on the provided status code
export const transformErrorData = (errors) => {
    const statusCode = errors.response ? errors.response.status : (errors.status || -1);

    const errorHandler = errorHandlers[statusCode] || (() => ({
        message: getErrorMessage(statusCode),
        statusCode: statusCode,
    }));

    if (statusCode === 422) {
        return handle422Error(errors);
    } else {
        return errorHandler();
    }
};

export const processDataForUpdate = (values, id, image) => {

    let shouldUpload = false
    let updatedImage = null

    if (values?.image.size !== undefined) {
        updatedImage = values?.image
        shouldUpload = true
    } else {
        updatedImage = image
    }

    return { ...values, _method: 'PUT', id, image: updatedImage, shouldUpload: shouldUpload }

}

// selected value set on change
export const setSelected = (field, selectedValue, setFieldValue) => {
    setFieldValue(field, selectedValue)
}


export const generateOptions = (capacity) => {
    const options = [];

    for (let i = 1; i <= capacity; i++) {
        options.push({ id: i, name: `${i}` });
    }

    return options;
};
