import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'customerPayment'

const prefix = convertNameToPrefix(name)

const endpoint = (data = '') => `${prefix}/${data}`

export const payment = createThunk(`${prefix}`, () => endpoint(), 'post', false)

export const customerPayment = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        reducerHandlers(builder, payment)
    },
});


export default customerPayment.reducer;


