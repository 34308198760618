import React, { useState } from "react"
import PageTitle from "app/components/common/PageTitle"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Status } from "../Status"
import PageContent from "../PageContent"
import { imageFromSource } from "services/CommonService"
import AppModal from "app/components/utils/AppModal"
import Edit from "app/components/pages/admin_panel/machine/actions/Edit"
import Delete from "app/components/pages/admin_panel/machine/actions/Delete"
import { DeleteButton, EditButton } from "app/components/utils/buttons"

const MachineDetails = () => {

    const { machineId } = useParams()

    const { data, status } = useSelector((state) => state.machine)

    const machine = data?.filter(machine => machine.id === +machineId)[0]

    const { data: auth } = useSelector((state) => state.auth)

    const role = auth.role

    const productCapacity = machine?.no_of_rows * machine?.no_of_columns * machine?.capacity

    const [modalData, setModalData] = useState(null)

    const handleEdit = () => {
        setModalData({
            title: 'Edit machine',
            body: Edit,
            data: machine
        });
    };

    const handleDelete = () => {
        setModalData({
            title: 'Delete machine',
            body: Delete,
            data: machine
        });
    };

    const closeModal = () => {
        setModalData(null);
    };

    const Machine = () => {
        return (
            <section className="card-components">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 d-flex flex-column p-4" style={{ minHeight: '100%' }}>
                            <div className="row p-2">
                                <div className="col-sm-12 col-md-6">
                                    <p className="mt-2 mb-2">Machine</p>
                                    <h3 className="mb-2">{machine?.machine_code} <Status isActive={machine?.is_active} isText={false} /></h3>
                                    <p className="text-sm">
                                        {machine?.location}
                                    </p>
                                    <p className="text-sm">
                                        Model: {machine?.machine_type?.name}
                                    </p>
                                </div>
                                {role == 'admin' &&
                                    <div className="col-sm-12 col-md-6">
                                        <p className="mt-2 mb-2">Vendor</p>
                                        <h3 className="mb-2">{machine?.vendor?.name}</h3>
                                        <p className="text-sm">
                                            {machine?.vendor?.business_name}
                                        </p>
                                        <p className="text-sm">
                                            <span className="text-medium">Email:</span>
                                            {machine?.vendor?.email}
                                        </p>
                                        <p className="text-sm">
                                            <span className="text-medium">Contact:</span>
                                            {machine?.vendor?.contact}
                                        </p>
                                    </div>
                                }
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="invoice-table table">
                                            <tbody>
                                                <tr>
                                                    <td className="fw-bold">
                                                        Features
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>No of rows: {machine?.no_of_rows}</li>
                                                            <li>No of channels: {machine?.no_of_columns}</li>
                                                            <li>Capacity of channel: {machine?.capacity}</li>
                                                            <li className="fw-bold mt-2">Product capacity: {productCapacity}</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="mt-2">Active from: {machine?.created_at}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
                            <div className="image" style={{ maxWidth: '200px', maxHeight: '200px', overflow: 'hidden' }}>
                                <img className="fit-image" src={imageFromSource(`machine_qr_code/${machine?.qr_code}`)} alt="qr-code" style={{ width: '100%', height: 'auto' }} />
                            </div>
                        </div>

                        {role === 'admin' &&
                            <div className="row mt-4">
                                <div className="col-12">
                                    <EditButton handleUpdate={handleEdit} className='mt-2 btn-xs' />
                                    <DeleteButton handleDelete={handleDelete} className='ms-md-2 mt-2 btn-xs' />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </section>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title='Machine Details' hasBack={true} />
            <PageContent status={status} component={Machine} />
            {modalData && (
                <AppModal modalData={modalData} modal={true} hideModal={closeModal} />
            )}
        </React.Fragment>
    )
}

export default MachineDetails;

