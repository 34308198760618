
import { Link } from "react-router-dom";
import AppModal from "../utils/AppModal";
import React, { useState } from "react";
import { imageFromSource } from "services/CommonService";
import { IMAGE_PATH } from "config";
import { Button } from "react-bootstrap";

const HomeScreen = () => {

    const [modalData, setModalData] = useState(null)

    const showMachineQr = () => {
        setModalData({
            body: machineQr,
            // data: machine
        });
    };

    const closeModal = () => {
        setModalData(null);
    };


    const machineQr = () => (
        <React.Fragment>
            <div className="d-flex flex-column align-items-center justify-content-center mb-4 p-4 pb-0">
                <img
                    src={imageFromSource('machine_qr_code/BS66423.png')}
                    alt="Logo"
                    className="img-fluid"
                    style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
                />
                <p className="fs-14 text-center mt-4">
                    or visit the store on your browser. <Link to="/store/1">Store</Link>
                </p>
            </div>

        </React.Fragment>
    )

    return (
        <React.Fragment>
            <div className="container d-flex justify-content-center align-items-center min-vh-100">
                <div className="border-0">
                    <div className="card-body">
                        <div style={{ position: 'absolute', top: '25px', left: '50%', transform: 'translateX(-50%)', textAlign: 'center', width: '100%' }}>
                            <h6 className="mb-4 fw-bold">Demo</h6>
                        </div>


                        <div style={{ marginBottom: 100, textAlign: 'center' }}>
                            <img src={`${IMAGE_PATH}/logo.png`} alt="Logo" style={{ width: '50%', height: 'auto', display: 'block', margin: '0 auto' }} />
                        </div>

                        <div className="row" >
                            <div className="col-sm-12 text-center">
                                <Link className='main-btn light-btn-outline btn-hover me-md-2 me-sm-0 mt-2' to='/admin/login'>Admin Login</Link>
                                <Link className='main-btn light-btn-outline btn-hover me-md-2 me-sm-0 mt-2' to='/vendor/login'>Vendor Login</Link>

                                <Button onClick={() => showMachineQr()} className='main-btn success-btn btn-hover mt-2' to='/store/1'>
                                    <i className="lni lni-wheelbarrow me-2"></i>
                                    Visit Store
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {modalData && (
                <AppModal modalData={modalData} modal={true} hideModal={closeModal} />
            )}
        </React.Fragment>

    )
}

export default HomeScreen