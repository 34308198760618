
let storeInstance;

export const setStoreInstance = (store) => {
    storeInstance = store;
};

export const getToken = () => {

    if (storeInstance) {
        const state = storeInstance.getState();
        return state.auth.data.access_token;
    }

    return null;
};

export const getRole = () => {

    if (storeInstance) {
        const state = storeInstance.getState();
        return state.auth.data.role;
    }

    return null;
};




