import React, { useState, useEffect } from "react";
import { CheckCircle } from "@mui/icons-material";
import { Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { order as completeOrder } from "features/CustomerSlice";
import { useNavigate } from "react-router-dom";
import { resetOrderData } from "features/OrderDataSlice";

const CompleteScreen = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [processing, setProcessing] = useState(false)

    const { data: machine } = useSelector((state) => state.customer)

    const { data: orderData } = useSelector((state) => state.orderData)

    const machineUrl = `/${machine?.machine_type?.name.toLowerCase()}/${machine.id}`

    useEffect(() => {
        const fetchDataAndDispatch = async () => {
            setProcessing(true);
            await dispatch(completeOrder(orderData));
            setProcessing(false);
        };

        fetchDataAndDispatch()
    }, [dispatch]);

    const handleBuyAgain = async () => {
        await dispatch(resetOrderData())
        navigate(machineUrl)
    }


    return (
        <Container className="d-flex justify-content-center align-items-center p-4" style={{ minHeight: "100vh" }}>
            <div className="text-center card-style-2 shadow-none" style={{ maxWidth: '600px' }}>
                <h4 className="fw-normal">
                    {processing ? "Product loading..." : "Collect your product !"}
                </h4>

                {processing ? (
                    <div className="my-5">
                        <div className="spinner-border text-success" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <CheckCircle className="text-success" style={{ fontSize: 100, marginTop: "50px" }} />
                )}

                {!processing && (
                    <div className="row mt-4">
                        <Button
                            variant="primary"
                            className="light-btn main-btn rounded-full mt-4"
                            onClick={handleBuyAgain}
                        >
                            Buy Again
                        </Button>
                    </div>
                )}
            </div>
        </Container>
    );
};

export default CompleteScreen;
