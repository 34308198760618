// MachineForm.js

import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Input, Select, Checkbox, File } from 'app/components/utils/form_elements';
import { setSelected } from 'services/CommonService';
import { CancelButton, UpdateButton, SaveButton } from 'app/components/utils/buttons';

const MachineForm = ({
    initialValues,
    validationSchema,
    onSubmit,
    vendorProps,
    bkashQrProps,
    machineTypeProps,
    noOfRowsProps,
    noOfColumnsProps,
    capacityProps,
    locationProps,
    isActiveProps,
    buttonTitle,
    handleCancel,
    action
}) => {

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ setFieldValue, errors, touched, values }) => (
                <Form>
                    <div className="select-style-1">
                        <div className="select-position">
                            <label>Machine Type</label>
                            <Select
                                onChange={(value) => setSelected('machine_type_id', value, setFieldValue)}
                                props={machineTypeProps}
                                error={errors.machine_type_id && touched.machine_type_id}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    </div>

                    <div className="input-style-1">
                        <label>No of Rows</label>
                        <Input props={noOfRowsProps} error={errors.no_of_rows && touched.no_of_rows} />
                    </div>

                    <div className="input-style-1">
                        <label>No of Channels</label>
                        <Input props={noOfColumnsProps} error={errors.no_of_columns && touched.no_of_columns} />
                    </div>

                    <div className="input-style-1">
                        <label>Channel capacity</label>
                        <Input props={capacityProps} error={errors.capacity && touched.capacity} />
                    </div>

                    <div className="select-style-1">
                        <div className="select-position">
                            <label>Vendor</label>
                            <Select
                                onChange={(value) => setSelected('vendor_id', value, setFieldValue)}
                                props={vendorProps}
                                error={errors.vendor_id && touched.vendor_id}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    </div>

                    <div className="input-style-1">
                        <label>Location</label>
                        <Input props={locationProps} error={errors.location && touched.location} />
                    </div>


                    <div className="input-style-1">
                        <label>bKash QR Code (Payment)</label>
                        <File props={bkashQrProps} setFieldValue={setFieldValue} error={errors.bkash_qr_code && touched.bkash_qr_code ? true : false} />
                    </div>

                    <div className="form-check form-switch toggle-switch mb-30">
                        <Field
                            name="is_active"
                            component={Checkbox}
                            props={isActiveProps}
                            label={values.is_active === 0 ? 'Maintenance On' : 'Machine Activated'}
                        />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {action === 'save' && <SaveButton title={buttonTitle} />}
                        {action === 'update' && <UpdateButton title={buttonTitle} />}
                        <CancelButton handleCancel={handleCancel} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default MachineForm;
