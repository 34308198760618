export const Status = ({ isActive, isText }) => {

    const status = isActive
        ?
        <span className="custom-small-text-12 d-inline text-dark">{isText && 'Active'} <span className="dot dot-active"></span></span>
        :
        <span className="custom-small-text-12 d-inline  text-dark">{isText && 'Deactive'} <span className="dot dot-deactive"></span></span>

    return status
}
