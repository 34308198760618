import React from "react"
import PageTitle from "app/components/common/PageTitle"
import { useSelector } from "react-redux"
import { imageFromSource } from "services/CommonService"
import PageContent from "app/components/common/PageContent"

const VendorProfile = () => {

    const { data, status } = useSelector((state) => state.auth)

    const vendor = data?.user

    const Machine = () => {
        return (
            <section className="card-components">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 d-flex flex-column p-4" style={{ minHeight: '100%' }}>
                            <div class="row p-2">
                                <div class="col-12">
                                    <h3 className="mb-2">{vendor?.name}</h3>
                                    <p class="text-sm">
                                        {vendor?.business_name}
                                    </p>
                                    <p class="text-sm">
                                        <span class="text-medium">Email:</span>
                                        {vendor?.email}
                                    </p>
                                    <p class="text-sm">
                                        <span class="text-medium">Contact:</span>
                                        {vendor?.contact}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div class="table-responsive">
                                        <table class="invoice-table table">
                                            <tbody>
                                                <tr>
                                                    <td className="fw-bold">
                                                        Details
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <ul>
                                                            <li>NID: {vendor?.nid ?? 'N/A'}</li>
                                                            <li>Trade licence: {vendor?.trade_licence ?? 'N/A'}</li>
                                                            <li>Additional contact: {vendor?.additional_contact ?? 'N/A'}</li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
                            <div className="image" style={{ maxWidth: '200px', maxHeight: '200px', overflow: 'hidden' }}>
                                <img className="fit-image" src={imageFromSource(`vendor/${vendor?.image}`)} alt={`${vendor?.name}`} style={{ width: '100%', height: 'auto' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title='Profile' hasBack={true} />
            <PageContent status={status} component={Machine} />
        </React.Fragment>
    )
}

export default VendorProfile;

