import { Formik, Form } from "formik";
import { Input } from "app/components/utils/form_elements";
import { CancelButton, SaveButton } from "app/components/utils/buttons";

const MachineTypeForm = ({ initialValues, validationSchema, onSubmit, handleCancel, machineTypeProps, buttonTitle }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="input-style-1">
                        <Input props={machineTypeProps} error={errors.name && touched.name ? true : false} />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <SaveButton title={buttonTitle} />
                        <CancelButton handleCancel={handleCancel} />
                    </div>
                </Form>
            )}
        </Formik >
    );
};

export default MachineTypeForm;
