import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from "react-router-dom"
import { Provider } from "react-redux"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'react-bootstrap-typeahead/css/Typeahead.css'

import './assets/css/main.css'
import './App.css'
import './assets/css/lineicons.css'
import './assets/css/loading-bar.min.css'
import './assets/css/morris.css'
import './assets/css/fullcalendar.css'

import store, { persistor } from './app/store'
import { PersistGate } from 'redux-persist/integration/react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(

  <React.Fragment>
    <BrowserRouter basename='/'>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
    <ToastContainer />
  </React.Fragment>

);

reportWebVitals();
