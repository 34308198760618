import React, { useEffect, useState } from "react"
import PageTitle from "app/components/common/PageTitle"
import { useSelector, useDispatch } from "react-redux"
import { fetch as fetchProduct } from "features/ProductSlice";
import PageContent from "app/components/common/PageTable";
import AssignProductTableContent from "./AssignProductTableContent";
import { useNavigate, useParams } from "react-router-dom";
import { updateMachine as assignProduct } from "features/MachineSlice";
import { CancelButton, CustomButton } from "app/components/utils/buttons";

const AssignProductList = () => {

    const { machineId } = useParams()

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { data: items, status } = useSelector((state) => state.product)

    const [selectedProducts, setSelectedProducts] = useState([])

    const onSubmit = async () => {

        const updatedValues = { products: JSON.stringify(selectedProducts), id: machineId, _method: 'PUT' }

        await dispatch(assignProduct(updatedValues))

        navigate(`/products/${machineId}`)
    };

    useEffect(() => {
        dispatch(fetchProduct())
    }, [dispatch])

    const handleClose = () => {
        navigate(`/products/${machineId}`)
    }

    const AssignProductTable = ({ items, status }) => {
        return (
            <div className="table-wrapper table-responsive mt-4">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Category</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.length > 0 &&
                            items?.map((item) => (
                                <AssignProductTableContent
                                    key={item.id}
                                    item={item}
                                    status={status}
                                    selectedProducts={selectedProducts}
                                    setSelectedProducts={setSelectedProducts}
                                />
                            ))}
                    </tbody>
                </table>

                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <CustomButton title='Assign Product' action={() => onSubmit()} icon='lni-checkmark-circle' btnClass='success-btn' />
                    <CancelButton handleCancel={handleClose} />
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title='Assign Product' />
            <PageContent items={items} status={status} component={AssignProductTable} />
        </React.Fragment>
    )
}

export default AssignProductList

