import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import Button from 'react-bootstrap/Button';
import { Input } from "app/components/utils/form_elements"
import { useDispatch, useSelector } from "react-redux";
import { LoginFormValidationRules } from "../validation";
import { login } from "features/AuthSlice";
import { useNavigate } from "react-router-dom";
import { Loading, STATUS } from "services/CommonService";
import ErrorMessage from "app/components/common/ErrorMessage";
import { persistor } from "app/store";

const LoginForm = ({ role }) => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const initialValues = { role: '', email: '', password: '' };

    const { data: auth, status, validationErrors } = useSelector((state) => state.auth);

    useEffect(() => {
        auth.role ? navigate(`/${auth.role}`) : navigate(`/${role}/login`)
    }, [navigate, auth.role])

    const onSubmit = async (values) => {

        const updatedValues = { ...values, role }

        await dispatch(login(updatedValues))

        if (status !== STATUS.IDLE) {
            persistor.purge()
        }
    }

    const emailProps = {
        name: 'email',
        type: 'email',
        placeholder: 'Enter email..',
    }

    const passwordProps = {
        name: 'password',
        type: 'password',
        placeholder: 'Enter password..',
    }

    return (
        <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={LoginFormValidationRules}
                onSubmit={onSubmit}
                enableReinitialize={true}
            >
                {({ errors, touched }) => (

                    <Form>
                        <div className="input-style-1 mt-4">
                            <label>Email</label>
                            <Input props={emailProps} error={errors.email && touched.email ? true : false} />
                        </div>

                        <div className="input-style-1">
                            <label>Password</label>
                            <Input props={passwordProps} error={errors.password && touched.password ? true : false} />
                        </div>

                        <Button type="submit" className="main-btn w-100 success-btn btn-hover">Login</Button>
                    </Form>
                )}
            </Formik >

            {status === STATUS.ERROR && <ErrorMessage errors={validationErrors} />}
            {status === STATUS.LOADING && <Loading />}
        </React.Fragment>
    )
}

export default LoginForm