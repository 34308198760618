


export const hideOverlay = () => {

    const sidebarNavWrapper = document.querySelector(".sidebar-nav-wrapper");
    const mainWrapper = document.querySelector(".main-wrapper");
    const overlay = document.querySelector(".overlay");

    sidebarNavWrapper.classList.remove("active");
    overlay.classList.remove("active");
    mainWrapper.classList.remove("active");
}

export const toggleAside = () => {

    const sidebarNavWrapper = document.querySelector(".sidebar-nav-wrapper");
    const mainWrapper = document.querySelector(".main-wrapper");
    const menuToggleButtonIcon = document.querySelector("#menu-toggle i");
    const overlay = document.querySelector(".overlay");

    sidebarNavWrapper.classList.toggle("active");
    overlay.classList.add("active");
    mainWrapper.classList.toggle("active");

    if (document.body.clientWidth > 1200) {
        if (menuToggleButtonIcon.classList.contains("lni-chevron-left")) {
            menuToggleButtonIcon.classList.remove("lni-chevron-left");
            menuToggleButtonIcon.classList.add("lni-menu");
        } else {
            menuToggleButtonIcon.classList.remove("lni-menu");
            menuToggleButtonIcon.classList.add("lni-chevron-left");
        }
    } else {
        if (menuToggleButtonIcon.classList.contains("lni-chevron-left")) {
            menuToggleButtonIcon.classList.remove("lni-chevron-left");
            menuToggleButtonIcon.classList.add("lni-menu");
        }
    }
}