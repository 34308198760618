import { useDispatch } from "react-redux";
import { CreateFormValidationRules } from "../validation";
import { save as saveVendor } from "features/VendorSlice";
import VendorForm from "./VendorForm";
import { useNavigate } from "react-router-dom";


const Create = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = async (values, onSubmitProps) => {
        const submission = await dispatch(saveVendor(values));

        if (submission?.payload?.success === true) {
            onSubmitProps.resetForm();
            navigate('/vendors');
        }
    };

    const initialValues = {
        name: '',
        email: '',
        password: '',
        image: '',
        contact: '',
        additional_contact: '',
        business_name: '',
        nid: '',
        trade_licence: ''
    };

    const nameProps = {
        name: 'name',
        type: 'text',
        placeholder: 'Choose vendor name..',
    }

    const contactProps = {
        name: 'contact',
        type: 'number',
        placeholder: 'Enter contact number..',
    };

    const additionalContactProps = {
        name: 'additional_contact',
        type: 'number',
        placeholder: 'Enter additional contact number..',
    };

    const emailProps = {
        name: 'email',
        type: 'email',
        placeholder: 'Enter email address..',
    };

    const passwordProps = {
        name: 'password',
        type: 'password',
        placeholder: 'Enter password..',
    };

    const imageProps = {
        name: 'image',
        type: 'file',
        placeholder: 'Choose image..',
    };

    const businessNameProps = {
        name: 'business_name',
        type: 'text',
        placeholder: 'Enter business name..',
    };

    const nidProps = {
        name: 'nid',
        type: 'text',
        placeholder: 'Enter NID..',
    };

    const tradeLicenceProps = {
        name: 'trade_licence',
        type: 'text',
        placeholder: 'Enter trade licence..',
    };

    const handleClose = () => {
        navigate('/vendors');
    }

    return (
        <VendorForm
            initialValues={initialValues}
            validationSchema={CreateFormValidationRules}
            onSubmit={onSubmit}
            enableReinitialize={true}
            nameProps={nameProps}
            contactProps={contactProps}
            additionalContactProps={additionalContactProps}
            emailProps={emailProps}
            passwordProps={passwordProps}
            imageProps={imageProps}
            businessNameProps={businessNameProps}
            nidProps={nidProps}
            tradeLicenceProps={tradeLicenceProps}
            handleCancle={handleClose}
            buttonTitle="Save vendor"
            action='save'
        />
    );
};

export default Create;
