import React from "react"
import { useDispatch } from "react-redux"
import { deleteMachine } from "features/MachineSlice"
import { CancelButton, RemoveButton } from "app/components/utils/buttons";
import { useNavigate } from "react-router-dom";
import { ENV } from "config";


const Delete = (props) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { handleClose, modalData } = props

    const { id, machine_code } = modalData.data

    const deleteItem = () => {
        dispatch(deleteMachine(id))
        handleClose()
        navigate('/machines')
    }

    return (
        <div className="accordion-body">
            <h4>Are you sure to delete {machine_code} ?</h4>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">

                {
                    ENV === 'demo' && id === 1 ?
                        <p>Cannot delete this machine on demo mode</p> :
                        <React.Fragment>
                            <RemoveButton handleRemove={() => deleteItem(id)} />
                            <CancelButton handleCancel={handleClose} />
                        </React.Fragment>
                }


            </div>
        </div>
    )
}

export default Delete