import { useDispatch, useSelector } from "react-redux"
import { UpdateFormValidationRules } from "../validation"
import { Formik, Form } from "formik"
import { Input } from "app/components/utils/form_elements"
import { updatePrice } from "features/MachineSlice"
import { CancelButton, SaveButton } from "app/components/utils/buttons"

const Edit = (props) => {

    const dispatch = useDispatch()

    const { handleClose, modalData } = props

    const { product_id, machine_id, price } = modalData.data.pivot

    const { data: auth } = useSelector((state) => state.auth)

    const vendor_id = auth?.user?.id


    const initialValues = { product_id, machine_id, vendor_id, price, _method: 'PUT' }

    const onSubmit = (values, onSubmitProps) => {

        dispatch(updatePrice(values)) && onSubmitProps.resetForm()

        handleClose()
    };

    const priceProps = {
        id: product_id,
        name: 'price',
        type: 'number',
        placeholder: 'Price..',
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={UpdateFormValidationRules}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="input-style-1">
                        <label>Price (BDT)</label>
                        <Input props={priceProps} error={errors.price && touched.price ? true : false} />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <SaveButton title="Set Price" />
                        <CancelButton handleCancel={handleClose} />
                    </div>
                </Form>
            )}
        </Formik >
    );
};

export default Edit
