import { fetch as fetchMachine } from 'features/CustomerSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import PageNotFound from '../../../common/PageNotFound';
import { Button } from 'react-bootstrap';

const WashMachine = () => {

    const washMachineTypeId = 3

    const { id } = useParams()

    const dispatch = useDispatch()

    const { data: machine } = useSelector((state) => state.customer)

    const [isMachine, setIsMachine] = useState(false)

    const navigate = useNavigate()

    const handleClick = (price) => {
        navigate(`/pay/${id}/${price}`)
    }

    useEffect(() => {
        dispatch(fetchMachine(id))
        const isMachineExist = machine?.id === parseInt(id, 10) && machine?.machine_type_id === washMachineTypeId
        if (isMachineExist) {
            setIsMachine(true)
        }
    }, [dispatch])

    const Machine = () => (
        <div className="d-flex justify-content-center align-items-center vh-100 mt-10">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div className="card card-style-2 mb-30" style={{ maxWidth: '30rem' }}>
                    <div className="card-image d-flex justify-content-center align-items-center">
                        <a href="#0">
                            <img
                                src="https://img.freepik.com/premium-photo/washing-machine-white-background-generative-ai_123447-11589.jpg"
                                className="card-img-top rounded-circle"
                                alt=""
                                style={{ height: '150px', width: '150px', objectFit: 'cover' }}
                            />
                        </a>
                    </div>
                    <div className="card-body">
                        {/* <h5 className="card-title">Choose your washing plan and start your laundry hassle-free!</h5> */}
                        <p className="card-text text-center">
                            Choose your <span className='fw-bold'>washing plan</span> and start your laundry hassle-free!
                        </p>
                        <div className="container p-0 mt-4">
                            <div className="row">
                                <div className="col-6">
                                    <ul>
                                        <li className="mb-2">
                                            <Button onClick={() => handleClick(50)} className="main-btn dark-btn-outline btn-hover w-100 d-block">
                                                <p className='fw-bold'>50 Tk</p>
                                                <span className='fw-normal'>(30 min)</span>
                                            </Button>
                                        </li>
                                        <li className="mb-2">
                                            <Button onClick={() => handleClick(150)} className="main-btn secondary-btn-outline btn-hover w-100 d-block">
                                                <p className='fw-bold'>150 Tk</p>
                                                <span className='fw-normal'>(1 hr 30 mins)</span>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-6">
                                    <ul>
                                        <li className="mb-2">
                                            <Button onClick={() => handleClick(100)} className="main-btn success-btn-outline btn-hover w-100 d-block">
                                                <p className='fw-bold'>100 Tk</p>
                                                <span className='fw-normal'>(1 hr)</span>
                                            </Button>
                                        </li>
                                        <li className="mt-2">
                                            <Button onClick={() => handleClick(200)} className="main-btn danger-btn-outline btn-hover w-100 d-block">
                                                <p className='fw-bold'>200 Tk</p>
                                                <span className='fw-normal'>(2 hrs)</span>
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        isMachine ? <Machine /> : <PageNotFound />
    );
};

export default WashMachine;
