import React, { useEffect, useState } from "react"
import PageTitle from "app/components/common/PageTitle"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import Refill from "./Refill"
import { STATUS, Loading } from "services/CommonService"

const RefillList = () => {

    const { machineId } = useParams()

    const { data: machines, status } = useSelector((state) => state.machine)

    const [machine, setMachine] = useState([])

    useEffect(() => {
        const machine = machines?.find(machine => machine.id === +machineId)
        setMachine(machine)
    }, [machines, machineId])


    const Columns = () => {
        const { no_of_columns, refills } = machine;

        const columnRows = [];
        for (let i = 0; i < refills?.length; i += no_of_columns) {
            const columns = refills?.slice(i, i + no_of_columns);
            columnRows.push(columns);
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {columnRows.map((refillItems, i) => (
                    <div key={i} className="p-4 pb-0" style={{ width: "100%", maxWidth: "900px", margin: "0 auto" }}>
                        <div className="row mb-3">
                            <h5>Row {i + 1}</h5>
                        </div>
                        <div style={{ display: "flex", gap: "16px" }} className="horizontal-scroll">
                            {refillItems.map((refillItem, col) => (
                                <div key={col} style={{ minWidth: "200px", maxWidth: "200px", flex: "0 0 auto" }}>
                                    <Refill
                                        machine={machine}
                                        rowNumber={i + 1}
                                        colNumber={col + 1}
                                        refill={refillItem}
                                        isProgressBar={true}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <React.Fragment>
            <PageTitle title='' hasBack={true} />
            <section className="card-components">
                <div className="container-fluid">
                    <Columns />
                </div>
            </section>
            {status === STATUS.LOADING && <Loading />}
        </React.Fragment>
    )
}

export default RefillList