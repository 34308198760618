import { Box, Container, Grid } from "@mui/material";
// import { fetch as fetchStore } from "features/StoreSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BottomSheet from "./BottomSheet";
import ProductCard from "./ProductCard";
import { useParams } from "react-router-dom";
import PageNotFound from '../../../common/PageNotFound';
import { fetch as fetchMachine } from "features/CustomerSlice";
import { cart } from "features/CartSlice";


const BoxMachine = () => {

    const boxMachineTypeId = 2

    const { id } = useParams()

    const dispatch = useDispatch()

    const { data: machine } = useSelector((state) => state.customer)

    const [isMachine, setIsMachine] = useState(false)

    const { products } = machine

    const cart = useSelector(state => state.cart)

    useEffect(() => {
        dispatch(fetchMachine(id))
        const isMachineExist = machine?.id === parseInt(id, 10) && machine?.machine_type_id === boxMachineTypeId
        if (isMachineExist) {
            setIsMachine(true)
        }
    }, [dispatch])

    const Machine = () => (
        <React.Fragment>
            <Container sx={{ py: 2, maxWidth: '500px !important', margin: 'auto' }}>
                <Grid container spacing={2}>
                    {products?.map((product) => (
                        <Grid item xs={6} md={6} key={product.id}>
                            <ProductCard product={product} />
                        </Grid>
                    ))}
                </Grid>
            </Container>

            {cart.length > 0 && <Box height={110} />}
            <BottomSheet show={cart.length} cart={cart} />
        </React.Fragment>
    );

    return (
        isMachine ? <Machine /> : <PageNotFound />
    );
};

export default BoxMachine;






