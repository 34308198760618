import { Image, Text } from "app/components/utils/columnElements"
import * as set from 'app/components/utils/ColumnData';


const TransactionDetails = ({ transaction }) => {

    return (
        <tr>
            <td>
                <Text data={set.text(transaction?.sold_at, 'fw-bold')} />
            </td>
            <td>
                <Image data={set.image(`product/${transaction?.product?.image}`)} />
            </td>
            <td>
                <Text data={set.text(transaction?.product?.name)} />
                <Text data={set.text(transaction?.product?.brand)} />
            </td>
            <td>
                <Text data={set.text(transaction?.status, `${transaction?.status === 'Completed' ? 'text-success' : 'text-danger'}`)} />
            </td>
            <td>
                <Text data={set.text(`৳ ${transaction?.total_amount}`)} />
            </td>
        </tr>
    );
};

export default TransactionDetails;
