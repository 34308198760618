import React from "react";

const ErrorMessage = ({ errors }) => {
    return (
        errors && Object.keys(errors).length > 0 && (
            <div className="error-container">
                <ul className="error-list">
                    {Object.keys(errors.errors).map((field) => {
                        const errorMessages = errors.errors[field];

                        const errorMessageToShow = errorMessages.length > 1
                            ? `${errorMessages[0]} (and ${errorMessages.length - 1} more errors)`
                            : errorMessages[0];

                        return (
                            <li className="error-item" key={field}>{errorMessageToShow}</li>
                        );
                    })}
                </ul>
            </div>
        )
    );
};

export default ErrorMessage;
