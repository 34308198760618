import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'auth'

const prefix = convertNameToPrefix(name)

export const login = createThunk(`${prefix}/login`, (data) => `${data.role}/login`, 'post', false);

export const logout = createThunk(`${prefix}/logout`, () => `logout`, 'post', true);

export const auth = createSlice({
    name,
    initialState,
    reducers: {
        reset() {
            return initialState
        },
    },
    extraReducers: (builder) => {
        reducerHandlers(builder, login);
        reducerHandlers(builder, logout);
    },
});

export const { reset } = auth.actions

export default auth.reducer;





