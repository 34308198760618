import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';

const CommonButton = ({
    onClickAction,
    text,
    iconClassName,
    btnClassName,
    className,
}) => {
    const navigate = useNavigate();

    const handleOnClick = () => {
        if (onClickAction === 'goBack') {
            navigate(-1);
        } else {
            onClickAction();
        }
    };

    return (
        <Button
            onClick={handleOnClick}
            className={`main-btn btn-sm ${btnClassName} ${className} btn-hover`}
        >
            {iconClassName && <i className={`lni ${iconClassName} left-icon`}></i>}
            <span className="btn-text">{text}</span>
        </Button>
    );
};

const SubmitButton = ({
    text,
    iconClassName,
    btnClassName,
    className,
}) => {
    return (
        <Button
            type="submit"
            className={`main-btn  btn-sm icon-btn ${btnClassName} ${className} btn-hover`}
        >
            {iconClassName && <i className={`lni ${iconClassName} left-icon`}></i>}
            <span className="btn-text">{text}</span>
        </Button>
    );
};


export const BackButton = ({ className }) => (
    <CommonButton
        onClickAction="goBack"
        text="Back"
        iconClassName="lni-chevron-left-circle"
        btnClassName="dark-btn-outline"
        className={className}
    />
);

export const CancelButton = ({ handleCancel, className }) => (
    <CommonButton
        onClickAction={handleCancel}
        text="Cancel"
        iconClassName="lni-cross-circle"
        btnClassName="btn-dark"
        className={className}
    />
);

export const CustomButton = (props) => (
    <CommonButton
        onClickAction={props?.action}
        text={props?.title}
        iconClassName={props?.icon}
        btnClassName={props?.btnClass}
    />
);

export const EditButton = ({ handleUpdate, className }) => (
    <CommonButton
        onClickAction={handleUpdate}
        text="Update"
        iconClassName="lni-pencil-alt right-icon"
        btnClassName="light-btn-outline"
        className={className}
    />
);

export const DeleteButton = ({ handleDelete, className }) => (
    <CommonButton
        onClickAction={handleDelete}
        text="Delete Permanently"
        iconClassName="lni-trash-can right-icon"
        btnClassName="danger-btn-outline"
        className={className}
    />
);

export const CreateButton = ({ handleCreate, title, icon = 'lni-circle-plus', className }) => (
    <CommonButton
        onClickAction={handleCreate}
        text={title}
        iconClassName={icon}
        btnClassName="success-btn-outline"
        className={className}
    />
);

export const SaveButton = ({ handleSave, title = 'Save', icon = 'lni-checkmark-circle', className }) => (
    <SubmitButton
        onClickAction={handleSave}
        text={title}
        iconClassName={icon}
        btnClassName="success-btn"
        className={className}
    />
);

export const UpdateButton = ({ handleUpdate, title = 'Update', icon = 'lni-checkmark-circle', className }) => (
    <SubmitButton
        onClickAction={handleUpdate}
        text={title}
        iconClassName={icon}
        btnClassName="btn-secondary"
        className={className}
    />
);


export const RemoveButton = ({ handleRemove, className }) => (
    <CommonButton
        onClickAction={handleRemove}
        text="Delete Permanently"
        iconClassName="lni-trash-can right-icon"
        btnClassName="btn-danger"
        className={className}
        style={{ '--hover-background-color': '#d50100' }}
    />
);


export default CommonButton;
