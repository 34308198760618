import React, { useEffect, useState } from "react"
import PageTitle from "app/components/common/PageTitle"
import { useSelector } from "react-redux"
import PageContent from "app/components/common/PageTable";
import MachineProductListTable from "./MachineProductListTable";
import { useParams } from "react-router-dom";


const MachineProductList = () => {

    const { data: auth } = useSelector((state) => state.auth)

    const { data: machines, status } = useSelector((state) => state.machine)

    const { machineId } = useParams()

    const [items, setItems] = useState([])

    useEffect(() => {
        const machine = machines?.find(machine => machine.id === +machineId)
        if (machine) {
            setItems(machine?.products)
        }
    }, [machines, setItems, machineId])

    const action = {
        hasAction: true,
        actionTitle: 'Assign Product',
        actionLink: `/product/assign/${machineId}`
    }

    const Products = ({ items, status }) => {
        return (
            <div className="table-wrapper table-responsive mt-4">
                <table className="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {items?.length > 0 &&
                            items?.map((item) => (
                                <MachineProductListTable
                                    key={item.id}
                                    item={item}
                                    status={status}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title='Product List' hasBack={true} action={auth.role === 'admin' && action} />
            <PageContent items={items} status={status} component={Products} />
        </React.Fragment>
    )
}

export default MachineProductList;

