import React from "react"
import PageTitle from "app/components/common/PageTitle"
import Machine from "app/components/common/machine/Machine";
import PageContent from "../PageContent";
import { Empty } from "services/CommonService";


const MachineList = (props) => {

    const { machines, status, action } = props

    const Machines = () => (
        <section className="card-components">
            <div className="container-fluid p-0">
                <div className="row">
                    {machines?.length > 0 ? machines?.map((machine) => <Machine key={machine} machine={machine} />) : <Empty props='No machine found' />}

                </div>
            </div>
        </section>
    )

    return (
        <React.Fragment>
            <PageTitle title='Machine List' action={action} />
            <PageContent component={Machines} status={status} />
        </React.Fragment>
    )
}

export default MachineList