import React from 'react';
import { Text, Image, Check } from 'app/components/utils/columnElements';
import * as set from 'app/components/utils/ColumnData';


const AssignProductTableContent = ({ item, selectedProducts, setSelectedProducts }) => {

    const handleChange = (productId) => {
        if (selectedProducts.includes(productId)) {
            setSelectedProducts(selectedProducts.filter((id) => id !== productId));
        } else {
            setSelectedProducts([...selectedProducts, productId]);
        }
    };

    return (
        <React.Fragment>
            <tr>
                <td><Image data={set.image(`product/${item.image}`)} /></td>
                <td>
                    <Text data={set.text(item?.name)} />
                    <Text data={set.text(item?.brand)} />
                </td>
                <td>
                    <div>
                        <Text data={set.text(item?.category?.name)} />
                    </div>
                </td>
                <td>
                    <Check
                        data={set.check(() => handleChange(item.id), selectedProducts.includes(item.id))}
                    />
                </td>
            </tr>
        </React.Fragment>
    );
};

export default AssignProductTableContent;
