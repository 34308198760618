import * as yup from "yup";

const RefillFormValidationRules = (maxPrice) =>
    yup.object().shape({
        product_id: yup.number().min(1, '*Product is required'),
        quantity: yup.number().nullable().required('*Quantity is required'),
        price: maxPrice !== null
            ? yup.number().nullable().max(maxPrice, `Price must be less than or equal to ${maxPrice}`).required('*Price is required')
            : yup.number().nullable().required('*Price is required'),
    });

export { RefillFormValidationRules };
