import { Status } from "app/components/common/Status";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Machine = ({ machine }) => {

    const [isVendingType, setIsVendingType] = useState(false)

    const { data: auth } = useSelector(state => state.auth)

    const role = auth.role

    useEffect(() => {
        if (machine.machine_type_id === 1 || machine.machine_type_id === 2) {
            setIsVendingType(true)
        }
    }, [machine])

    const VendingTypeButton = () => (
        <React.Fragment>
            {/* <div className="row">
                <div className="col-12  mt-2 mb-2">
                    <p className="fs-14">Refill Status</p>
                    <ProgressBar now={machine?.refills_sum_quantity} />
                </div>
            </div> */}

            <div className="d-grid gap-2 mt-2">

                {
                    role === 'vendor' &&
                    <Link to={`/refills/${machine?.id}`} className="btn-sm btn-height-6 main-btn success-btn-light  btn-hover">
                        Refill
                    </Link>
                }

                <Link to={`/machine/${machine?.id}`} className="btn-sm btn-height-6 main-btn light-btn-outline btn-hover">
                    Details
                </Link>

                <Link to={`/products/${machine?.id}`} className="btn-sm btn-height-6 main-btn light-btn-outline btn-hover" >Products</Link>

                <Link to={`/transactions/${machine?.id}`} className="btn-sm btn-height-6 main-btn light-btn-outline  btn-hover" type="button">Transactions</Link>

            </div>
        </React.Fragment>
    )

    const OtherTypeButton = () => (
        <React.Fragment>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="d-grid gap-2">
                        <Link to={`/products/${machine?.id}`} className="btn-sm btn-height-6 main-btn success-btn-light  btn-hover">Settings</Link>

                        <Link to={`/transactions/${machine?.id}`} className="btn-sm btn-height-6 main-btn light-btn-outline btn-hover">Transactions</Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )

    return (
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
            <div className={`card-style-2 shadow-none mb-30 p-4`}>
                <div className="row">
                    <div className="col-12">
                        <div className="card-title">
                            <div className="mb-2">
                                <h4>{machine?.machine_code}</h4>
                            </div>
                            <div>
                                <p className="fw-light fs-14">
                                    Model: {machine?.machine_type?.name}
                                    <span className="ms-1 text-primary"></span>
                                </p>

                                <p className="fw-light fs-14">
                                    Location: {machine?.location}
                                    <span className="ms-1 text-primary"></span>
                                </p>

                                <p className="fw-light mb-1 fs-14">
                                    Status: <Status isActive={machine?.is_active} isText={true} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="card-content">
                        {isVendingType ? <VendingTypeButton /> : <OtherTypeButton />}
                    </div >
                </div >
            </div >
        </div >
    )
}


export default Machine