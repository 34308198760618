import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'transaction'

const prefix = convertNameToPrefix(name)

export const fetch = createThunk(`${prefix}/fetch`, (id) => `transactions/${id}`, 'get', false)

export const transaction = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        reducerHandlers(builder, fetch)
    },
});

export default transaction.reducer;





