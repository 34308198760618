import React from "react"
import { Route } from "react-router-dom"
import VendorDashboard from "app/components/pages/vendor_panel/dashboard"
import RefillList from "app/components/pages/vendor_panel/machine/components/refill/RefillList"
import Logout from "app/components/common/Logout"
import TransactionList from "app/components/common/transaction/TransactionList"
import MachineProductList from "app/components/common/machine/MachineProductList"
import MachineDetails from "app/components/common/machine/MachineDetails"
import VendorMachineList from "app/components/pages/vendor_panel/machine/VendorMachineList"
import VendorProfile from "app/components/pages/vendor_panel/VendorProfile"

const vendorRoutes = () => (
    <React.Fragment>
        <Route path="/vendor" element={<VendorDashboard />} />
        <Route path="/profile" element={<VendorProfile />} />
        <Route path={'/machines'} element={<VendorMachineList />} />
        <Route path={'/machine/:machineId'} element={<MachineDetails />} />
        <Route path={'/refills/:machineId'} element={<RefillList />} />
        <Route path={'/products/:machineId'} element={<MachineProductList />} />
        <Route path={'/transactions/:machineId'} element={<TransactionList />} />
        <Route path="/logout" element={<Logout />} />
    </React.Fragment>
)

export default vendorRoutes