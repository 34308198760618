import { useNavigate } from "react-router-dom";
import React from "react";
import { CreateButton, BackButton } from "../utils/buttons";

const PageTitle = (props) => {

    const { title, action, hasBack } = props

    const navigate = useNavigate()

    const handleCreate = () => {
        navigate(action?.actionLink)
    }

    return (
        <React.Fragment>
            <div className="title-wrapper pt-30">
                <div className="row align-items-center">
                    <div className="col-lg-6 title mb-30">
                        <h4>{title}</h4>
                    </div>

                    <div className="col-lg-6 title mb-30 d-grid gap-2 d-md-flex justify-content-md-end">
                        {action?.hasAction &&
                            <CreateButton handleCreate={handleCreate} title={action?.actionTitle} />
                        }
                        {hasBack && <BackButton />}
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}

export default PageTitle