import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchVendorMachines } from "features/MachineSlice";
import MachineList from "app/components/common/machine/MachineList";

const VendorMachineList = () => {

    const dispatch = useDispatch()

    const { data: auth } = useSelector(state => state.auth)

    const userId = auth.user.id

    useEffect(() => {
        dispatch(fetchVendorMachines(userId))
    }, [dispatch, userId])

    const { data: machines, status } = useSelector((state) => state.machine)

    return <MachineList machines={machines} status={status} />
}

export default VendorMachineList