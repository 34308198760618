import React from "react";

const Checkbox = ({ props, label, form: { values, setFieldValue }, field }) => {
    const { name, ...rest } = props;

    const handleChange = (e) => {
        const newValue = e.target.checked ? 1 : 0;
        setFieldValue(name, newValue);
    };

    return (
        <React.Fragment>
            <input
                {...rest}
                {...field}
                id={name}
                className='form-check-input'
                type="checkbox"
                checked={values[name] === 1}
                onChange={handleChange}
            />
            <label className="form-check-label mt-2" htmlFor={name}>{label}</label>
        </React.Fragment>
    );
};


export default Checkbox