import React from "react";

const MachineSales = ({ data }) => {

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-12 col-md-6">
                    <div className="card-style mb-30">
                        <div
                            className="
                    title
                    d-flex
                    flex-wrap
                    justify-content-between
                    align-items-center
                  "
                        >
                            <div className="left">
                                <h6 className="text-medium mb-30">Sales Today</h6>
                            </div>
                            {/* <div className="right">
                            <div className="select-style-1">
                                <div className="select-position select-sm">
                                    <select className="light-bg">
                                        <option value="">Yearly</option>
                                        <option value="">Monthly</option>
                                        <option value="">Weekly</option>
                                    </select>
                                </div>
                            </div>
                        </div> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table top-selling-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <h6 className="text-sm text-medium">Machine</h6>
                                        </th>
                                        <th className="min-width">
                                            <h6 className="text-sm text-medium">Location</h6>
                                        </th>
                                        <th className="min-width">
                                            <h6 className="text-sm text-medium">Sold</h6>
                                        </th>
                                        <th className="min-width">
                                            <h6 className="text-sm text-medium">Amount</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.today_machine_sales.map(sale => (
                                        <tr key={sale.machine_id}>
                                            <td>
                                                <p className="text-sm">{sale?.machine_code}</p>
                                            </td>
                                            <td>
                                                <p className="text-sm text-truncate">{sale?.location}</p>
                                            </td>
                                            <td>
                                                <p className="text-sm">{sale?.today_total_sales}</p>
                                            </td>
                                            <td>
                                                <p className="text-sm">{sale?.today_total_amount}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



                <div className="col-sm-12 col-md-6">
                    <div className="card-style mb-30">
                        <div
                            className="
                    title
                    d-flex
                    flex-wrap
                    justify-content-between
                    align-items-center
                  "
                        >
                            <div className="left">
                                <h6 className="text-medium mb-30">Sales Total</h6>
                            </div>
                            {/* <div className="right">
                            <div className="select-style-1">
                                <div className="select-position select-sm">
                                    <select className="light-bg">
                                        <option value="">Yearly</option>
                                        <option value="">Monthly</option>
                                        <option value="">Weekly</option>
                                    </select>
                                </div>
                            </div>
                        </div> */}
                        </div>
                        <div className="table-responsive">
                            <table className="table top-selling-table">
                                <thead>
                                    <tr>
                                        <th>
                                            <h6 className="text-sm text-medium">Machine</h6>
                                        </th>
                                        <th className="min-width">
                                            <h6 className="text-sm text-medium">Location</h6>
                                        </th>
                                        <th className="min-width">
                                            <h6 className="text-sm text-medium">Sold</h6>
                                        </th>
                                        <th className="min-width">
                                            <h6 className="text-sm text-medium">Amount</h6>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.machine_sales.map(sale => (
                                        <tr key={sale.machine_id}>
                                            <td>
                                                <p className="text-sm">{sale?.machine_code}</p>
                                            </td>
                                            <td>
                                                <p className="text-sm text-truncate">{sale?.location}</p>
                                            </td>
                                            <td>
                                                <p className="text-sm">{sale?.total_sales}</p>
                                            </td>
                                            <td>
                                                <p className="text-sm">{sale?.total_amount}</p>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default MachineSales
