import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "services/ReducerService";

const name = 'orderData'

export const orderData = createSlice({
    name,
    initialState,
    reducers: {
        saveOrderData(state, action) {
            state.data = action.payload
        },

        resetOrderData() {
            return initialState
        }
    }
});


export const { saveOrderData, resetOrderData } = orderData.actions
export default orderData.reducer;


