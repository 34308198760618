import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'dashboard'

const prefix = convertNameToPrefix(name)

const endpoint = (data = '') => `${prefix}/${data}`

export const fetchAdminDashboard = createThunk(`${prefix}/admin/fetch`, () => endpoint(), 'get', false);

export const fetchVendorDashboard = createThunk(`${prefix}/vendor/fetch`, (id) => endpoint(id), 'get', false);

export const dashboard = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        reducerHandlers(builder, fetchAdminDashboard);
        reducerHandlers(builder, fetchVendorDashboard);
    },
});

export default dashboard.reducer;





