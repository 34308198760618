import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CreateFormValidationRules } from '../validation';
import { createMachine, } from 'features/MachineSlice';
import MachineForm from './MachineForm';
import { fetch as fetchMachineType } from 'features/MachineTypeSlice';
import { fetch as fetchVendors } from 'features/VendorSlice';
import { useNavigate } from "react-router-dom";
import { STATUS } from 'services/CommonService';
import ErrorMessage from 'app/components/common/ErrorMessage';

const Create = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { data: machineType } = useSelector((state) => state.machineType)

    const { data: vendors } = useSelector((state) => state.vendor)

    const { status, validationErrors } = useSelector((state) => state.machine)

    useEffect(() => {
        dispatch(fetchVendors())
        dispatch(fetchMachineType())
    }, [dispatch])

    const initialValues = {
        vendor_id: null,
        machine_type_id: null,
        no_of_rows: null,
        no_of_columns: null,
        capacity: null,
        bkash_qr_code: null,
        location: null,
        is_active: 0
    };

    const onSubmit = async (values, onSubmitProps) => {
        const submission = await dispatch(createMachine(values))

        if (submission?.payload?.success === true) {
            onSubmitProps.resetForm()
            navigate('/machines');
        }
    }

    const vendorProps = {
        name: 'vendor_id',
        placeholder: 'Choose vendor',
        filterBy: null,
        optionFields: vendors,
    };

    const machineTypeProps = {
        name: 'machine_type_id',
        placeholder: 'Choose machine types..',
        filterBy: null,
        optionFields: machineType,
    };

    const noOfRowsProps = {
        name: 'no_of_rows',
        type: 'number',
        placeholder: 'Enter no of rows..',
    };

    const noOfColumnsProps = {
        name: 'no_of_columns',
        type: 'number',
        placeholder: 'Enter no of channels..',
    };

    const capacityProps = {
        name: 'capacity',
        type: 'number',
        placeholder: 'Enter channel capacity..',
    };

    const bkashQrProps = {
        name: 'bkash_qr_code',
        type: 'file',
        placeholder: 'Upload bKash QR code..',
    };

    const locationProps = {
        name: 'location',
        type: 'text',
        placeholder: 'Enter location..',
    }

    const isActiveProps = {
        name: 'is_active',
        type: 'checkbox',
    };

    const handleCancel = () => navigate('/machines');

    return (
        <React.Fragment>
            <MachineForm
                initialValues={initialValues}
                validationSchema={CreateFormValidationRules}
                onSubmit={onSubmit}
                vendorProps={vendorProps}
                machineTypeProps={machineTypeProps}
                noOfRowsProps={noOfRowsProps}
                noOfColumnsProps={noOfColumnsProps}
                capacityProps={capacityProps}
                bkashQrProps={bkashQrProps}
                locationProps={locationProps}
                isActiveProps={isActiveProps}
                handleCancel={handleCancel}
                buttonTitle="Save Machine"
                action='save'
            />
            {status === STATUS.ERROR && <ErrorMessage errors={validationErrors} />}
        </React.Fragment>
    );
};

export default Create;
