import { Formik, Form } from "formik";
import { Input } from "app/components/utils/form_elements";
import { CancelButton, UpdateButton, SaveButton } from 'app/components/utils/buttons';

const ProductCategoryForm = ({ initialValues, validationSchema, onSubmit, handleCancel, categoryProps, buttonTitle, action }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ errors, touched }) => (
                <Form>
                    <div className="input-style-1">
                        <Input props={categoryProps} error={errors.name && touched.name ? true : false} />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {action === 'save' && <SaveButton title={buttonTitle} />}
                        {action === 'update' && <UpdateButton title={buttonTitle} />}
                        <CancelButton handleCancel={handleCancel} />
                    </div>
                </Form>
            )}
        </Formik >
    );
};

export default ProductCategoryForm;
