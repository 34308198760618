// Edit.js

import { useDispatch } from "react-redux";
import { UpdateFormValidationRules } from "../validation";
import { update as updateCategory } from "features/ProductCategorySlice";
import ProductCategoryForm from "./ProductCategoryForm";

const Edit = (props) => {

    const dispatch = useDispatch();

    const { handleClose, modalData } = props;

    const { id, name } = modalData.data;

    const initialValues = { name };

    const onSubmit = (values, onSubmitProps) => {

        const updatedValues = { ...values, id, _method: 'PUT' }

        dispatch(updateCategory(updatedValues)) && onSubmitProps.resetForm();

        handleClose();
    };

    const categoryProps = {
        id: id,
        name: 'name',
        type: 'text',
        placeholder: 'Choose product category name..',
    };

    return (
        <ProductCategoryForm
            initialValues={initialValues}
            validationSchema={UpdateFormValidationRules}
            onSubmit={onSubmit}
            handleCancel={handleClose}
            categoryProps={categoryProps}
            buttonTitle="Update category"
            action='update'
        />
    );
};

export default Edit;
