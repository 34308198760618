import { Formik, Form } from "formik";
import { Input, Select } from "app/components/utils/form_elements"
import { RefillFormValidationRules } from "../validation";
import { useDispatch, useSelector } from "react-redux";
import { saveRefill } from "features/MachineSlice";
import { generateOptions, setSelected } from "services/CommonService";
import { useState } from "react";
import { CancelButton, SaveButton } from "app/components/utils/buttons";
import { Link } from "react-router-dom";

const RefillForm = (props) => {

    const dispatch = useDispatch()

    const { data: auth } = useSelector(state => state.auth)

    const { handleClose } = props

    const vendorId = auth?.user?.id;

    const { machine, rowNumber, colNumber, refill } = props.modalData.info

    const [maxPrice, setMaxPrice] = useState(null);

    const [isPrice, setIsPrice] = useState(false);


    const initialValues = {
        vendor_id: vendorId,
        machineId: machine?.id,
        row_no: rowNumber,
        column_no: colNumber,
        product_id: refill?.product_id,
        quantity: refill?.quantity,
        price: refill?.price
    }

    const onSubmit = (values, onSubmitProps) => {

        const updatedValues = { ...values, product_id: +values.product_id, quantity: +values.quantity }

        dispatch(saveRefill(updatedValues)) && onSubmitProps.resetForm()
    }

    const productsProps = {
        name: 'product_id',
        placeholder: 'Choose product..',
        filterBy: null,
        optionFields: machine.products
    }

    const quantityProps = {
        name: 'quantity',
        placeholder: 'Number of products..',
        filterBy: null,
        optionFields: generateOptions(refill?.capacity),
    }

    const priceProps = {
        name: 'price',
        placeholder: 'Enter price..',
        type: 'number',
        max: maxPrice,
        disabled: isPrice, // Disable when isPrice is true (price is null)
    }


    const handleProductChange = async (field, selectedValue, setFieldValue) => {

        if (+selectedValue === 0) return

        setFieldValue(field, +selectedValue);
        const product = machine.products.find((product) => product.id === +selectedValue);
        const price = product?.pivot?.price;

        setIsPrice(price === null); // Set isPrice based on the price
        setFieldValue('price', price !== null ? price : ''); // Set price or clear input value
        setMaxPrice(price);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RefillFormValidationRules(maxPrice)}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ setFieldValue, errors, touched }) => (
                <Form>
                    <div className="select-style-1">
                        <div className="select-position">
                            <label>Product</label>
                            <Select
                                onChange={(value) => handleProductChange('product_id', value, setFieldValue)}
                                props={productsProps}
                                error={errors.products_id && touched.products_id}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    </div>

                    <div className="input-style-1">
                        <label>Price (BDT)</label>
                        <Input
                            props={priceProps}
                            error={errors.price && touched.price ? true : false}
                        />
                        {isPrice && (
                            <div className="fs-12 mt-1">
                                Price is not set. <Link to={`/products/${machine?.id}`}>Set price.</Link>
                            </div>
                        )}
                    </div>

                    <div className="select-style-1">
                        <div className="select-position">
                            <label>Quantity</label>
                            <Select
                                onChange={(value) => setSelected('quantity', value, setFieldValue)}
                                props={quantityProps}
                                error={errors.quantity && touched.quantity}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <SaveButton title="Save Refill" />
                        <CancelButton handleCancel={handleClose} />
                    </div>
                </Form>
            )}
        </Formik >
    )
}

export default RefillForm