import { useDispatch } from "react-redux";
import { CreateFormValidationRules } from "../validation";
import { save as saveMachineType } from "features/MachineTypeSlice";
import MachineTypeForm from "./MachineTypeForm";
import { useNavigate } from "react-router-dom";

const Create = () => {

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const initialValues = { name: '' };

    const onSubmit = async (values, onSubmitProps) => {
        const submission = await dispatch(saveMachineType(values));

        if (submission?.payload?.success === true) {
            onSubmitProps.resetForm();
            navigate('/machine/type');
        }
    };

    const machineTypeProps = {
        name: 'name',
        type: 'text',
        placeholder: 'Enter type..',
    };

    const handleClose = () => {
        navigate('/machine/type')
    }


    return (
        <MachineTypeForm
            initialValues={initialValues}
            validationSchema={CreateFormValidationRules}
            onSubmit={onSubmit}
            handleCancel={handleClose}
            machineTypeProps={machineTypeProps}
            buttonTitle="Save machine type"
        />
    );
};

export default Create;
