import { useDispatch } from "react-redux";
import { UpdateFormValidationRules } from "../validation";
import { update as updateVendor } from "features/VendorSlice";
import VendorForm from "./VendorForm";
import { processDataForUpdate } from "services/CommonService";

const Edit = (props) => {

    const dispatch = useDispatch()

    const { handleClose, modalData } = props

    const { id, name, email, image, contact, additional_contact, business_name, nid, trade_licence } = modalData.data

    const initialValues = { name, email, image, contact, additional_contact, business_name, nid, trade_licence }

    const onSubmit = (values, onSubmitProps) => {

        const updatedValues = processDataForUpdate(values, id, image)

        dispatch(updateVendor(updatedValues)) && onSubmitProps.resetForm()

        handleClose()
    }

    const nameProps = {
        name: 'name',
        type: 'text',
        placeholder: 'Choose vendor name..',
    }

    const contactProps = {
        name: 'contact',
        type: 'number',
        placeholder: 'Enter contact number..',
    }

    const additionalContactProps = {
        name: 'additional_contact',
        type: 'number',
        placeholder: 'Enter additional contact number..',
    }

    const emailProps = {
        name: 'email',
        type: 'email',
        placeholder: 'Enter email address..',
    }

    const passwordProps = {
        name: 'password',
        type: 'password',
        placeholder: 'Enter password..',
    }

    const imageProps = {
        name: 'image',
        type: 'file',
        placeholder: 'Choose image..',
    }

    const businessNameProps = {
        name: 'business_name',
        type: 'text',
        placeholder: 'Enter business name..',
    }

    const nidProps = {
        name: 'nid',
        type: 'text',
        placeholder: 'Enter NID..',
    }

    const tradeLicenceProps = {
        name: 'trade_licence',
        type: 'text',
        placeholder: 'Enter trade licence..'
    }

    return (
        <VendorForm
            initialValues={initialValues}
            validationSchema={UpdateFormValidationRules}
            onSubmit={onSubmit}
            enableReinitialize={true}
            nameProps={nameProps}
            contactProps={contactProps}
            additionalContactProps={additionalContactProps}
            emailProps={emailProps}
            passwordProps={passwordProps}
            imageProps={imageProps}
            businessNameProps={businessNameProps}
            nidProps={nidProps}
            tradeLicenceProps={tradeLicenceProps}
            handleCancel={handleClose}
            buttonTitle="Update vendor"
            action='update'
        />
    )
}

export default Edit
