import { imageFromSource, imageNotFound } from "services/CommonService";

export const text = (data, style) => ({
    text: data,
    style: style || 'text-normal'
});

export const image = (data, style) => ({
    src: data ? imageFromSource(data) : imageNotFound(),
    style: `${style} image-in-list light-image-border`
});

export const button = (action, style, iconStyle) => ({
    action: action,
    style: style || 'text-dark',
    iconStyle: iconStyle
});

export const check = (action, checked, style) => ({
    checked: checked,
    action: action,
    style: style || 'form-check-input'
});