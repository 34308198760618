import React from "react"
import { Outlet } from "react-router-dom"
import Header from "./header"
import Aside from "./aside"


const UserLayout = () => {
    return (
        <main className="main-wrapper">
            <Header />
            <Aside />
            <section className="tab-components">
                <div className="container-fluid">
                    <Outlet />
                </div>
            </section>
        </main>
    )
}

export default UserLayout