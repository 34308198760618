import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'customer'

const prefix = convertNameToPrefix(name)

const endpoint = (data = '') => `${prefix}/${data}`

export const fetch = createThunk(`${prefix}/fetch`, (id) => endpoint(`machine/${id}`), 'get', false)

export const order = createThunk(`${prefix}/order`, () => endpoint(`order`), 'post', false)

export const customer = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        reducerHandlers(builder, fetch);
        reducerHandlers(builder, order);
    },
});

export default customer.reducer;





