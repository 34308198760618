
export const Check = ({ data }) => (
    <div className="check-input-primary">
        <input
            className={data.style}
            type='checkbox'
            id={data.id}
            onChange={data.action}
            checked={data.checked}
        />
    </div>
)

export const Image = ({ data }) => (
    <img src={data.src} className={data.style} alt={data.alt} />
)

export const Text = ({ data }) => (
    <p className={data.style}>{data.text}</p>
)


export const Toggle = ({ data }) => (
    <span className={`status-btn ${data.is_active ? 'active-btn' : 'close-btn'} ${data.style}`}>
        {data.is_active ? 'Active' : 'Not Active'}
    </span>
)

export const Button = ({ data }) => (
    <button name={data.name} onClick={data.action} className={data.style}>
        <i className={data.iconStyle}></i>
    </button>
)