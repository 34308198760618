import { saveOrderData } from "features/OrderDataSlice";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { imageFromSource, imageNotFound } from "services/CommonService";


const ConfirmScreen = ({ refill, products, setIsConfirmScreen, machine }) => {

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const [quantity, setQuantity] = useState(1);

    const [totalPrice, setTotalPrice] = useState(refill?.price);

    const image = refill?.product?.image ? imageFromSource(`product/${refill?.product?.image}`) : imageNotFound();

    const handleIncreaseQuantity = () => {
        const newQuantity = quantity + 1;
        if (newQuantity <= 5) {
            setQuantity(newQuantity);
            setTotalPrice(newQuantity * refill?.price);
        } else {
            toast.error('Max 5 products can be added')
        }
    };

    const handleDecreaseQuantity = () => {
        const newQuantity = quantity > 1 ? quantity - 1 : 1;
        setQuantity(newQuantity);
        setTotalPrice(newQuantity * refill?.price);
    };

    const handleCancel = () => {
        setIsConfirmScreen(false)
    };

    const handleConfirm = async () => {

        const data = {
            machine_id: machine.id,
            row_no: refill?.row_no,
            column_no: refill?.column_no,
            price: totalPrice,
            quantity: quantity,
            product_id: refill?.product_id
        }

        await dispatch(saveOrderData(data))

        navigate(`/pay/${machine.id}/${totalPrice}`);
    }

    const getProductPrice = (productId) => {
        const product = products.filter(product => product.id === parseInt(productId, 10))
        return product[0]?.pivot?.price
    }

    const Price = ({ refillItem }) => {

        const originalPrice = getProductPrice(refillItem?.product?.id)

        const refillPrice = refillItem?.price

        const isDiscounted = originalPrice > refillPrice ? true : false


        return (
            <h3 className="text-dark fw-bold">
                {refillPrice ? `${refillPrice} Tk` : (<span className="invisible">Hidden text</span>)}

                {
                    isDiscounted && (<span className="fw-normal custom-small-text-16 text-decoration-line-through text-danger ms-2"> {originalPrice} Tk</span>)
                }
            </h3>
        );
    };

    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '80vh' }}>
                <div className="row">
                    <div className='card-style-2 shadow-none'>
                        <div className="row">
                            <div className="col-sm-12 col-md-4 d-flex justify-content-center align-items-center">
                                <div className="image" style={{ maxWidth: '200px', maxHeight: '200px', overflow: 'hidden' }}>
                                    <img className="fit-image" src={image} alt="" style={{ width: '100%', height: 'auto' }} />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-8 d-flex flex-column p-4" style={{ minHeight: '100%' }}>
                                <div>
                                    <div className="row mt-2 mb-2">
                                        <div className="col-12">
                                            <div className="card-title text-truncate">
                                                <h3 className="fw-normal">{refill?.product?.name}</h3>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <Price refillItem={refill} />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="row mb-4 mt-4">
                                        <div className="col-sm-12 col-md-8 mt-4 mb-4 d-flex justify-content-start align-item-middle">
                                            <h3 className="pt-2 fw-normal">
                                                Total : {refill?.price} x {quantity} = <span className="fw-bold ">{totalPrice} Tk</span>
                                            </h3>
                                        </div>
                                        <div className="col-sm-12 col-md-4 mt-4 mb-4 ">
                                            <div className="d-flex justify-content-center">
                                                <Button className="p-2 main-btn deactive-btn-outline square-btn rounded-start col-6 fw-bold text-dark" onClick={handleDecreaseQuantity}>
                                                    -
                                                </Button>
                                                <Button className="p-1 main-btn deactive-btn-outline square-btn rounded-end col-6 fw-bold text-dark" onClick={handleIncreaseQuantity}>
                                                    +
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-2 mb-2">
                                        <div className="col-12">
                                            <div className="d-flex justify-content-between">
                                                <Button className="main-btn dark-btn-outline btn-hover flex-grow-1 me-2" onClick={handleCancel}>
                                                    Cancel
                                                </Button>
                                                <Button onClick={handleConfirm} className="main-btn success-btn btn-hover flex-grow-1" >
                                                    Confirm & pay with bKash
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    );
};

export default ConfirmScreen;
