import { Routes, Route } from "react-router-dom"
import PageNotFound from "app/components/common/PageNotFound";
import adminRoutes from "./AdminRoutes"
import vendorRoutes from "./VendorRoutes"
import customerRoutes from "./CustomerRoutes"
import AuthLayout from "app/components/common/AuthLayout";
import CustomerLayout from "app/components/common/CustomerLayout";
import { useSelector } from "react-redux";

const RootRoute = () => {

    const { data: auth } = useSelector((state) => state.auth)

    return (
        <Routes>
            <Route path='/' element={<CustomerLayout />}>
                {customerRoutes()}
            </Route>
            <Route path='/' element={<AuthLayout />}>
                {auth.role === 'admin' && adminRoutes()}
                {auth.role === 'vendor' && vendorRoutes()}
            </Route>
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export default RootRoute
