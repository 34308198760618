import { createSlice } from "@reduxjs/toolkit";
import { reducerHandlers, createThunk, convertNameToPrefix, initialState } from "services/ReducerService";

const name = 'product'

const prefix = convertNameToPrefix(name)

const endpoint = (data = '') => `${prefix}/${data}`

export const fetch = createThunk(`${prefix}/fetch`, () => endpoint(), 'get', false);

export const save = createThunk(`${prefix}/save`, () => endpoint(), 'post', true);

export const update = createThunk(`${prefix}/update`, (data) => endpoint(data.id), 'post', true);

export const remove = createThunk(`${prefix}/delete`, (id) => endpoint(id), 'delete', true);

export const product = createSlice({
    name,
    initialState,
    extraReducers: (builder) => {
        reducerHandlers(builder, fetch);
        reducerHandlers(builder, save);
        reducerHandlers(builder, update);
        reducerHandlers(builder, remove);
    },
});

export default product.reducer;


