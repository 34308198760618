import React, { useState } from 'react';
import { Text, Image } from 'app/components/utils/columnElements';
import * as set from 'app/components/utils/ColumnData';
import AppModal from "app/components/utils/AppModal";
import Edit from "../../pages/vendor_panel/machine/components/product/actions/Edit";
import { useSelector } from 'react-redux';
import { CustomButton } from 'app/components/utils/buttons';

const MachineProductListTable = ({ item }) => {

    const { data: auth } = useSelector((state) => state.auth)

    const [modalData, setModalData] = useState(null);

    const updatePrice = () => {
        setModalData({
            title: 'Set price',
            body: Edit,
            data: item
        });
    };

    const closeModal = () => {
        setModalData(null);
    };

    return (
        <React.Fragment>
            <tr>
                <td><Image data={set.image(`product/${item.image}`)} /></td>
                <td>
                    <Text data={set.text(item?.name)} />
                    <Text data={set.text(item?.brand)} />
                </td>
                <td>
                    <Text data={set.text(item?.category?.name)} />
                </td>
                <td>
                    {
                        item?.pivot?.price ? <Text data={set.text(`৳ ${item?.pivot?.price}`, 'fw-bold')} /> : <span className='text-gray text-sm'>Price not set</span>
                    }

                </td>

                {auth.role === 'vendor' &&
                    <td>
                        <div className='d-flex justify-content-end'>
                            <CustomButton title='Set price' action={() => updatePrice()} icon='lni-checkmark-circle' btnClass='light-btn-outline' />
                        </div>
                    </td>
                }
            </tr>
            {modalData && (
                <AppModal modalData={modalData} modal={true} hideModal={closeModal} />
            )}
        </React.Fragment>
    );
};

export default MachineProductListTable;
