import { Field, ErrorMessage } from "formik";
import ErrorText from "app/components/common/ErrorText";

const Select = ({ onChange, props, error, setFieldValue }) => {
    const { name, placeholder, filterBy, optionFields, ...rest } = props;

    const handleChange = (event) => {
        const { value } = event.target;
        if (onChange && setFieldValue) {
            onChange(value, setFieldValue);
        }
    };

    const options = filterBy === null ? optionFields : optionFields.filter((field) => field.id !== filterBy.id);
    const selectProps = {
        name,
        as: "select",
        ...rest,
        onChange: handleChange,
        className: error === true ? 'form-control shadow-none is-invalid' : null
    };

    return (
        <>
            <Field {...selectProps}>
                {/* Render only when there's no filterBy or options */}
                {!filterBy && !options.length && <option value=''>{placeholder}</option>}
                {filterBy === null ? <option value=''>{placeholder}</option> : <option value={filterBy.id}>{filterBy.name}</option>}
                {options.map((option) => (
                    <option key={option.id} value={option.id}>{option.name || option.machine_code}</option>
                ))}
            </Field>
            <ErrorMessage name={name} component={ErrorText} />
        </>
    )
}

export default Select;
