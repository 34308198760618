import React from "react"
import { useDispatch } from "react-redux"
import { remove as deleteProduct } from "features/ProductSlice"
import { CancelButton, RemoveButton } from "app/components/utils/buttons";

const Delete = (props) => {

    const dispatch = useDispatch()

    const { handleClose, modalData } = props

    const { id, name } = modalData.data

    const deleteItem = id => dispatch(deleteProduct(id)) && handleClose()

    return (
        <div className="accordion-body">
            <h4>Are you sure to delete {name} ?</h4>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-4">
                <RemoveButton handleRemove={() => deleteItem(id)} />
                <CancelButton handleCancel={handleClose} />
            </div>
        </div>
    )
}

export default Delete