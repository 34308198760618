import React from "react";
import { Loading, STATUS } from "services/CommonService";

const PageContent = ({ status, component }) => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card-style">
                        {status === STATUS.LOADING && <Loading />}
                        {component()}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};

export default PageContent;
