import React, { useState } from 'react';
import Delete from "./actions/Delete";
import AppModal from "app/components/utils/AppModal";
import { Text, Button } from 'app/components/utils/columnElements';
import * as set from 'app/components/utils/ColumnData';
import { ENV } from 'config';


const MachineTypeTableContent = ({ item }) => {
    const [modalData, setModalData] = useState(null);

    const DeleteBody = () => {
        if (ENV === 'demo') {
            return <p>Cannot delete in demo mode</p>;
        } else {
            return <Delete />;
        }
    };


    const handleDelete = () => {
        setModalData({
            title: 'Delete type',
            body: DeleteBody,
            data: item
        });
    };

    const closeModal = () => {
        setModalData(null);
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <div>
                        <Text data={set.text(item.name)} />
                    </div>
                </td>
                <td>
                    <div className="action d-flex justify-content-end">
                        <Button data={set.button(() => handleDelete(), 'ms-3 text-dark', 'lni lni-trash-can text-danger fs-14')} />
                    </div>
                </td>
            </tr>
            {modalData && (
                <AppModal modalData={modalData} modal={true} hideModal={closeModal} />
            )}
        </React.Fragment>
    );
};

export default MachineTypeTableContent;
