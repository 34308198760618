// Edit.js

import { useDispatch, useSelector } from 'react-redux';
import { UpdateFormValidationRules } from '../validation';
import { updateMachine } from 'features/MachineSlice';
import MachineForm from './MachineForm';
import { fetch as fetchMachineType } from 'features/MachineTypeSlice';
import { fetch as fetchVendor } from 'features/VendorSlice';
import { useEffect } from 'react';

const Edit = (props) => {

    const dispatch = useDispatch();

    const { data: machineType } = useSelector((state) => state.machineType)

    const { data: vendors } = useSelector((state) => state.vendor)

    useEffect(() => {
        dispatch(fetchVendor())
        dispatch(fetchMachineType())
    }, [dispatch])

    const { handleClose, modalData } = props

    const { id, no_of_rows, no_of_columns, capacity, vendor, machine_type, location, is_active, machine_types_id, vendors_id } = modalData.data

    const initialValues = { id, machine_types_id, no_of_rows, no_of_columns, capacity, location, vendors_id, is_active };

    const onSubmit = (values, onSubmitProps) => {

        const updatedValues = { ...values, id, _method: 'PUT' }

        dispatch(updateMachine(updatedValues)) && onSubmitProps.resetForm()

        handleClose()
    };

    const vendorProps = {
        name: 'vendor_id',
        placeholder: 'Choose vendor',
        filterBy: vendor,
        optionFields: vendors,
        type: 'number'
    };

    const machineTypeProps = {
        name: 'machine_type_id',
        placeholder: 'Choose machine types..',
        filterBy: machine_type,
        optionFields: machineType,
        type: 'number',
        disabled: true
    };

    const noOfRowsProps = {
        name: 'no_of_rows',
        type: 'number',
        placeholder: 'Enter no of rows..',
        disabled: true
    };

    const capacityProps = {
        name: 'capacity',
        type: 'number',
        placeholder: 'Enter channel capacity..',
        disabled: true
    };

    const noOfColumnsProps = {
        name: 'no_of_columns',
        type: 'number',
        placeholder: 'Enter no of channels..',
        disabled: true
    };

    const bkashQrProps = {
        name: 'bkashQr',
        type: 'file',
        placeholder: 'Upload bKash QR code..',
    };

    const locationProps = {
        name: 'location',
        type: 'text',
        placeholder: 'Enter location..',
    }

    const isActiveProps = {
        name: 'is_active',
        type: 'checkbox',
    };

    return (
        <MachineForm
            initialValues={initialValues}
            validationSchema={UpdateFormValidationRules}
            onSubmit={onSubmit}
            vendorProps={vendorProps}
            machineTypeProps={machineTypeProps}
            noOfRowsProps={noOfRowsProps}
            noOfColumnsProps={noOfColumnsProps}
            capacityProps={capacityProps}
            bkashQrProps={bkashQrProps}
            locationProps={locationProps}
            isActiveProps={isActiveProps}
            handleCancel={handleClose}
            buttonTitle="Update Machine"
            action='update'
        />
    );
};

export default Edit;
