import { Route } from "react-router-dom"
import React from "react";
// import CustomerDashboard from "app/components/pages/customer_panel/dashboard"
import Registration from "app/components/pages/auth/Registration";
import StoreMachine from "app/components/pages/customer_panel/screens/StoreMachine";
import BoxMachine from "app/components/pages/customer_panel/screens/WashMachine";
import WashMachine from "app/components/pages/customer_panel/screens/BoxMachine";
import DryMachine from "app/components/pages/customer_panel/screens/DryMachine";
import ChargeMachine from "app/components/pages/customer_panel/screens/ChargeMachine";
import CheckoutScreen from "app/components/pages/customer_panel/screens/CheckoutScreen";
import PaymentScreen from "app/components/pages/customer_panel/screens/PaymentScreen";
import CompleteScreen from "app/components/pages/customer_panel/screens/CompleteScreen";
import AdminLogin from "app/components/pages/auth/AdminLogin";
import VendorLogin from "app/components/pages/auth/VendorLogin";
import HomeScreen from "app/components/common/HomeScreen";


const customerRoutes = () => (
    <React.Fragment>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/vendor/login" element={<VendorLogin />} />
        <Route path="/register" element={<Registration />} />
        <Route path="/store/:id" element={<StoreMachine />} />
        <Route path="/box/:id" element={<BoxMachine />} />
        <Route path="/wash/:id" element={<WashMachine />} />
        <Route path="/dry/:id" element={<DryMachine />} />
        <Route path="/charge/:id" element={<ChargeMachine />} />
        <Route path={'/checkout'} element={<CheckoutScreen />} />
        <Route path={'pay/:id/:price'} element={<PaymentScreen />} />
        <Route path={'/complete'} element={<CompleteScreen />} />
    </React.Fragment>
);

export default customerRoutes