import React, { useEffect, useState } from "react";
import PageTitle from "app/components/common/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetch as fetchMachine } from "features/CustomerSlice";
import ConfirmScreen from "./ConfirmScreen";
import StoreProduct from "./StoreProduct";
import { IMAGE_PATH } from "config";


const StoreMachine = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { data: machine } = useSelector((state) => state.customer);
    const [isConfirmScreen, setIsConfirmScreen] = useState(false);
    const [confirmRefill, setConfirmRefill] = useState({});

    useEffect(() => {
        dispatch(fetchMachine(id))
    }, [dispatch, id]);

    const Columns = () => {
        const { no_of_columns, refills } = machine;
        const columnRows = [];
        for (let i = 0; i < refills?.length; i += no_of_columns) {
            const columns = refills?.slice(i, i + no_of_columns);
            columnRows.push(columns);
        }

        return (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                {columnRows.map((refillItems, i) => (
                    <div key={i} className="p-4 pb-0" style={{ width: "100%", maxWidth: "900px", margin: "0 auto" }}>
                        <div className="row mb-2">
                            <h5 className="mb-2">Row {i + 1}</h5>
                        </div>
                        <div style={{ display: "flex", gap: "16px" }} className="horizontal-scroll">
                            {refillItems.map((refillItem, col) => (
                                <div key={col} className="col-sm-6 col-md-4 col-lg-3" style={{ minWidth: "200px", maxWidth: "200px", flex: "0 0 auto" }}>
                                    <StoreProduct
                                        machine={machine}
                                        rowNumber={i + 1}
                                        colNumber={col + 1}
                                        refill={refillItem}
                                        isProgressBar={false}
                                        setIsConfirmScreen={setIsConfirmScreen}
                                        setConfirmRefill={setConfirmRefill}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };



    return (
        <React.Fragment>
            <section className="card-components">
                <div className="d-flex justify-content-center mb-4 w-sm-100 w-md-50" style={{ marginTop: 60 }}>
                    <img src={`${IMAGE_PATH}/logo.png`} alt="Logo" className="img-fluid" style={{ width: 100, height: 'auto', display: 'block' }} />
                </div>
                <div className="container-fluid">
                    {isConfirmScreen ? <ConfirmScreen machine={machine} refill={confirmRefill} products={machine.products} setIsConfirmScreen={setIsConfirmScreen} /> : <Columns />}
                </div>
            </section>
        </React.Fragment>
    );
};

export default StoreMachine;
