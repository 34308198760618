import { Formik, Form } from "formik";
import { Input, Select, File } from "app/components/utils/form_elements";
import { setSelected } from "services/CommonService";
import { CancelButton, UpdateButton, SaveButton } from 'app/components/utils/buttons';

const ProductForm = ({
    initialValues,
    validationSchema,
    onSubmit,
    handleCancel,
    categoryProps,
    nameProps,
    brandProps,
    imageProps,
    buttonTitle,
    action
}) => {

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form encType="multipart/form-data">
                    <div className="input-style-1">
                        <label>Name</label>
                        <Input props={nameProps} error={errors.name && touched.name ? true : false} />
                    </div>

                    <div className="select-style-1">
                        <label>Category</label>
                        <div className="select-position">
                            <Select
                                onChange={(value) => setSelected('product_category_id', value, setFieldValue)}
                                props={categoryProps}
                                error={errors.product_category_id && touched.product_category_id}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                    </div>

                    <div className="input-style-1">
                        <label>Brand</label>
                        <Input props={brandProps} error={errors.brand && touched.brand ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Image</label>
                        <File props={imageProps} setFieldValue={setFieldValue} error={errors.image && touched.image ? true : false} />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {action === 'save' && <SaveButton title={buttonTitle} />}
                        {action === 'update' && <UpdateButton title={buttonTitle} />}
                        <CancelButton handleCancel={handleCancel} />
                    </div>
                </Form>
            )}
        </Formik >
    );
};

export default ProductForm;
