import { useDispatch } from "react-redux";
import { CreateFormValidationRules } from "../validation";
import { save as saveCategory } from "features/ProductCategorySlice";
import ProductCategoryForm from "./ProductCategoryForm";
import { useNavigate } from "react-router-dom";

const Create = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialValues = { name: '' };

    const onSubmit = async (values, onSubmitProps) => {
        const submission = await dispatch(saveCategory(values));

        if (submission?.payload?.success === true) {
            onSubmitProps.resetForm();
            navigate('/product/category');
        }
    };

    const categoryProps = {
        name: 'name',
        type: 'text',
        placeholder: 'Enter category name..',
    };

    const handleClose = () => {
        navigate('/product/category');
    };

    return (
        <ProductCategoryForm
            initialValues={initialValues}
            validationSchema={CreateFormValidationRules}
            onSubmit={onSubmit}
            handleCancel={handleClose}
            categoryProps={categoryProps}
            buttonTitle="Save category"
            action="save"
        />
    );
};

export default Create;
