import AppModal from "app/components/utils/AppModal";
import React, { useState } from "react";
import { ProgressBar } from "react-bootstrap"
import { imageFromSource, imageNotFound } from "services/CommonService";
import RefillForm from "./actions/RefillForm";


const Refill = (props) => {

    const { machine, rowNumber, colNumber, refill, isProgressBar } = props

    const [modal, setModal] = useState(false)

    const isRefilled = refill?.product_id ? true : false

    const modalData = {
        title: `Row: ${rowNumber} - Column: ${colNumber}`,
        body: RefillForm,
        info: {
            machine, rowNumber, colNumber, refill
        }
    }

    const getProductPrice = (productId) => {
        const product = machine.products.filter(product => product.id === parseInt(productId, 10))
        return product[0]?.pivot?.price
    }

    const image = isRefilled ? imageFromSource(`product/${refill?.product?.image}`) : imageNotFound()

    const showModal = () => setModal(modal => !modal)

    const hideModal = () => setModal(false)

    const Price = ({ refillItem }) => {

        const originalPrice = getProductPrice(refillItem?.id)

        const refillPrice = refillItem?.price

        const isDiscounted = originalPrice > refillPrice ? true : false


        return (
            <p className="text-dark fw-bold">
                {refillPrice ? `${refillPrice} Tk` : (<span className="invisible">Hidden text</span>)}

                {
                    isDiscounted && isRefilled && (<span className="fw-normal text-decoration-line-through text-danger fs-12 ms-2"> {originalPrice} Tk</span>)
                }
            </p>
        );
    };



    return (
        <React.Fragment>
            <AppModal modalData={modalData} modal={modal} hideModal={hideModal} />
            <div className="col-12 mb-4">
                <div className={`card-style-2 p-3 clickable ${!isRefilled && 'empty'}`} onClick={showModal}>
                    <div>
                        <p className={`fw-bold fs-12`}>
                            Channel: {colNumber}
                        </p>
                    </div>

                    <div className="row mt-2 mb-2 p-2">
                        <div className="col-12 d-flex justify-content-center">
                            <div className="image">
                                <img className="fit-image" src={image} alt="" />
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="card-title text-truncate">
                                {isRefilled ? (
                                    <p className="fw-normal fs-14">{refill?.product && refill?.product?.name}</p>
                                ) : (<h6 className="mt-2 fs-14 fw-light text-center" style={{ color: '#cdcdcd' }}>Product not avaiable..</h6>)}

                            </div>
                        </div>
                    </div>


                    <div className="mb-1">
                        <div className="col-12">
                            <Price refillItem={refill} />
                        </div>
                    </div>

                    {

                        isProgressBar && (
                            <ProgressBar now={refill?.quantity * 10} label={refill?.quantity + ' / ' + refill?.capacity} className="border-radius-10" />
                        )
                    }


                </div>
            </div>
        </React.Fragment >
    )
}

export default Refill