import React, { useState } from 'react';
import { Text } from 'app/components/utils/columnElements';
import * as set from 'app/components/utils/ColumnData';
import TransactionDetails from './TranasactionDetails';


const TransactionListTable = ({ item }) => {

    const [expanded, setExpanded] = useState(false);

    const expand = () => {
        setExpanded(!expanded);
    };

    return (
        <React.Fragment>
            <tr
                onClick={() => expand()}
                style={{
                    cursor: 'pointer',
                    backgroundColor: expanded ? 'whitesmoke' : 'transparent',
                    fontWeight: expanded ? 'bold' : 'normal',
                }}

            >
                <td style={{ paddingLeft: '0.5rem' }}>
                    <Text data={set.text(item?.date)} />
                </td>
                <td style={{ textAlign: 'right', paddingRight: '0.5rem' }} className='fw-bold'>
                    <Text data={set.text(`Total: ৳ ${item?.total_amount}`, 'fw-bold')} />
                </td>
            </tr>
            {expanded && (
                <tr>
                    <td colSpan="2">
                        <div className="row">
                            <div className="col-12">
                                <div className="invoice-card p-2 pt-0">
                                    {item.data?.length > 0 && (
                                        <div className="table-responsive" style={{ overflow: 'hidden' }}>
                                            <table className="table">
                                                <tbody>
                                                    {item.data.map((data, dataIndex) => (
                                                        <TransactionDetails
                                                            key={dataIndex}
                                                            transaction={data}
                                                        />
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
};

export default TransactionListTable;
