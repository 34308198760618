import React from "react";
import PageTitle from "app/components/common/PageTitle";

const TopSection = ({ data }) => {

    return (
        <React.Fragment>
            <PageTitle title='Dashboard' />
            <div className="row">
                <div className="col-md-3 col-sm-12">
                    <div className="icon-card mb-30">
                        <div className="icon">
                            <i className="lni lni-cart-full"></i>
                        </div>
                        <div className="content">
                            <h6 className="mb-10">Today Sold</h6>
                            <h3 className="text-bold mb-10">{data?.today_total_sales}</h3>
                            {/* <p className="text-sm text-success">
                                <i className="lni lni-arrow-up"></i> +2.00%
                                <span className="text-gray">(30 days)</span>
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-12">
                    <div className="icon-card mb-30">
                        <div className="icon success">
                            <span>&#2547;</span>
                        </div>
                        <div className="content">
                            <h6 className="mb-10">Today Amount</h6>
                            <h3 className="text-bold mb-10">{data?.today_total_amount}</h3>
                            {/* <p className="text-sm text-success">
                                <i className="lni lni-arrow-up"></i> +5.45%
                                <span className="text-gray">Increased</span>
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-12">
                    <div className="icon-card mb-30">
                        <div className="icon">
                            <i className="lni lni-cart-full"></i>
                        </div>
                        <div className="content">
                            <h6 className="mb-10">Total Sold</h6>
                            <h3 className="text-bold mb-10">{data?.total_sales}</h3>
                            {/* <p className="text-sm text-success">
                                <i className="lni lni-arrow-up"></i> +2.00%
                                <span className="text-gray">(30 days)</span>
                            </p> */}
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-sm-12">
                    <div className="icon-card mb-30">
                        <div className="icon danger-light">
                            <span>&#2547;</span>
                        </div>
                        <div className="content">
                            <h6 className="mb-10">Total Amount</h6>
                            <h3 className="text-bold mb-10">{data?.total_amount}</h3>
                            {/* <p className="text-sm text-success">
                                <i className="lni lni-arrow-up"></i> +5.45%
                                <span className="text-gray">Increased</span>
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default TopSection