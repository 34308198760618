import {
    useLocation,
    Navigate,
    Outlet
} from "react-router-dom";
import UserLayout from "./UserLayout";
import { useSelector } from "react-redux";

const AuthLayout = () => {

    const { data: auth } = useSelector((state) => state.auth)

    const location = useLocation()

    return (
        auth.role ? <UserLayout><Outlet /></UserLayout>
            : <Navigate to='/' state={{ from: location }} replace />
    )
}

export default AuthLayout;