
import { IMAGE_PATH } from "config";
import LoginForm from "./actions/LoginForm";
import { ENV } from "config";


const VendorLogin = () => {

    return (
        <div className="container d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
            <div className="col-sm-12 col-md-5">
                <div className="card border-0 p-4">
                    <div className="card-body">
                        <div className="d-flex justify-content-center">
                            <img src={`${IMAGE_PATH}/logo.png`} alt="Logo" style={{ width: '30%', height: 'auto', display: 'block' }} />
                        </div>
                        <h4 className="card-title fw-bold">Login</h4>
                        <LoginForm role='vendor' />
                        {ENV === 'demo' &&
                            <div className="note-wrapper success-alert mt-4">
                                <div className="alert">
                                    <ul>
                                        <li className="text-gray">email: vendor@bvend.com </li>
                                        <li className="text-gray">password: 123456</li>
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>


    )
}

export default VendorLogin