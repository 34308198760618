import React, { useEffect, useState } from "react"
import PageTitle from "app/components/common/PageTitle"
import { useSelector } from "react-redux"
import PageContent from "app/components/common/PageTable";
import { useParams } from "react-router-dom";
import TransactionListTable from "app/components/common/transaction/TransactionListTable";


const TransactionList = () => {

    const { data: machines, status } = useSelector((state) => state.machine)

    const { machineId } = useParams()

    const [items, setItems] = useState([])

    const [machine, setMachine] = useState([])

    useEffect(() => {
        const machine = machines.find(machine => machine.id === +machineId)
        if (machine) {
            setMachine(machine)
            setItems(machine.transactions)
        }
    }, [machines, setItems, machineId])

    const Transactions = ({ items, status }) => {
        return (
            <div className="table-wrapper table-responsive mt-4">
                <table className="table">
                    <tbody>
                        {items?.length > 0 &&
                            items?.map((item) => (
                                <TransactionListTable
                                    key={item?.data?.id}
                                    item={item}
                                    status={status}
                                    machine={machine}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <React.Fragment>
            <PageTitle title={`All Transactions`} hasBack={true} />
            <PageContent items={items} machine={machine} status={status} component={Transactions} />
        </React.Fragment>
    )
}

export default TransactionList;

