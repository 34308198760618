import React, { useState } from 'react';
import { Text, Image } from 'app/components/utils/columnElements';
import * as set from 'app/components/utils/ColumnData';
import { useNavigate } from 'react-router-dom';
import { CustomButton } from 'app/components/utils/buttons';

const VendorTableContent = ({ item }) => {

    const navigate = useNavigate()

    const handleClick = () => {
        navigate(`/vendor/${item.id}`)
    }

    return (
        <React.Fragment>
            <tr className='clickable' onClick={handleClick}>
                <td><Image data={set.image(`vendor/${item.image}`)} /></td>
                <td>
                    <Text data={set.text(item?.name)} />
                </td>
                <td>
                    <Text data={set.text(item?.business_name ?? 'N/A')} />
                </td>
                <td>
                    <div className='d-flex justify-content-end' >
                        <CustomButton title='See details' btnClass='btn light-btn-outline btn-xs' />
                        {/* <Text data={set.text('See details')} /> */}
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
};

export default VendorTableContent;
