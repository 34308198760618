import { Formik, Form } from "formik";
import { Input, File } from "app/components/utils/form_elements";
import { CancelButton, UpdateButton, SaveButton } from 'app/components/utils/buttons';

const VendorForm = ({
    initialValues,
    validationSchema,
    onSubmit,
    handleCancel,
    nameProps,
    contactProps,
    additionalContactProps,
    emailProps,
    passwordProps,
    imageProps,
    businessNameProps,
    nidProps,
    tradeLicenceProps,
    buttonTitle,
    action
}) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize={true}
        >
            {({ errors, touched, setFieldValue }) => (
                <Form encType="multipart/form-data">
                    <div className="input-style-1">
                        <label>Name</label>
                        <Input props={nameProps} error={errors.name && touched.name ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Contact No.</label>
                        <Input props={contactProps} error={errors.contact && touched.contact ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Additional Contact No.</label>
                        <Input props={additionalContactProps} error={errors.additional_contact && touched.additional_contact ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Email</label>
                        <Input props={emailProps} error={errors.email && touched.email ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Password</label>
                        <Input props={passwordProps} error={errors.password && touched.password ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Business Name</label>
                        <Input props={businessNameProps} error={errors.business_name && touched.business_name ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>NID No.</label>
                        <Input props={nidProps} error={errors.nid && touched.nid ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Trade Licence No.</label>
                        <Input props={tradeLicenceProps} error={errors.trade_licence && touched.trade_licence ? true : false} />
                    </div>

                    <div className="input-style-1">
                        <label>Profile Image</label>
                        <File props={imageProps} setFieldValue={setFieldValue} error={errors.image && touched.image ? true : false} />
                    </div>

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {action === 'save' && <SaveButton title={buttonTitle} />}
                        {action === 'update' && <UpdateButton title={buttonTitle} />}
                        <CancelButton handleCancel={handleCancel} />
                    </div>
                </Form>
            )}
        </Formik >
    );
};

export default VendorForm;
