import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { fetchMachines } from "features/MachineSlice";
import MachineList from "app/components/common/machine/MachineList";


const action = {
    hasAction: true,
    actionTitle: 'Add Machine',
    actionLink: '/machine/create'
}

const AdminMachineList = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchMachines())
    }, [dispatch])

    const { data: machines, status } = useSelector((state) => state.machine)

    return <MachineList machines={machines} action={action} status={status} />
}

export default AdminMachineList;

