// Create.js

import { useDispatch, useSelector } from "react-redux";
import { CreateFormValidationRules } from "../validation";
import { useEffect, useState } from "react";
import { fetch as fetchCategory } from "features/ProductCategorySlice";
import { save as saveProduct } from "features/ProductSlice";
import ProductForm from "./ProductForm";
import { useNavigate } from "react-router-dom";


const Create = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { data: categories } = useSelector((state) => state.productCategory);

    useEffect(() => {
        dispatch(fetchCategory());
    }, [dispatch]);

    const onSubmit = async (values, onSubmitProps) => {
        const submission = await dispatch(saveProduct(values));

        if (submission?.payload?.success === true) {
            onSubmitProps.resetForm();
            navigate('/products');
        }
    };

    const initialValues = { name: '', product_category_id: '', image: '', brand: '' };

    const nameProps = {
        name: 'name',
        type: 'text',
        placeholder: 'Choose product name..',
    };

    const brandProps = {
        name: 'brand',
        type: 'text',
        placeholder: 'Choose brand name..',
    };

    const handleClose = () => {
        navigate('/products');
    }

    const categoryProps = {
        name: 'product_category_id',
        placeholder: 'Choose product category..',
        filterBy: null,
        optionFields: categories,
    };

    const imageProps = {
        name: 'image',
        placeholder: 'Choose product image..',
        type: 'file'
    };

    return (
        <ProductForm
            initialValues={initialValues}
            validationSchema={CreateFormValidationRules}
            onSubmit={onSubmit}
            enableReinitialize={true}
            categoryProps={categoryProps}
            nameProps={nameProps}
            brandProps={brandProps}
            imageProps={imageProps}
            handleCancel={handleClose}
            buttonTitle="Create Product"
            action="save"
        />
    );
};

export default Create;
