import axios from 'axios';
import { getToken } from 'services/AuthService';

const axiosInstance = axios.create({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
    },
});


axiosInstance.interceptors.request.use(
    (config) => {
        const token = getToken()

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.withCredentials = false;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
