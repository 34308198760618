import { Add, Remove } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { addToCart, removeFromCart } from "features/CartSlice";
import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexBetween, FlexCenter } from "../styles";
import { IMAGE_PATH } from "config";
// import { Button } from "react-bootstrap";


const ProductCard = ({ product }) => {


  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)

  const cartItem = cart.find(item => item.id === product.id)

  const imagePath = (image) => `${IMAGE_PATH}/product/${image}`

  const handleAddToCart = productId => {
    const findIndex = cart.findIndex((item) => item.id === productId)
    if (findIndex === -1 && cart.length > 4) alert("Can't choose more than 5 products !!")
    else dispatch(addToCart(product))
  }

  return (
    <div className="card-style-2 p-2">
      <FlexCenter sx={{ height: 150, overflow: "hidden" }}>
        <img src={imagePath(product.image)} width="50%" alt="Product" />
      </FlexCenter>

      <Box py={1} px={{ xs: 1.5, sm: 2 }}>
        <Typography variant="p" fontSize={13} fontWeight={600} color="primary">
          {product.name.substring(0, 12)}
        </Typography>

        <Typography variant="h6" fontSize={16} fontWeight={700}>
          {product.price} Tk
        </Typography>

        <FlexBetween mt={1}>
          {/*  b */}


          <Button fullWidth variant="contained" onClick={() => handleAddToCart(product.id)} >
            <Add sx={{ fontSize: 14, color: "#fff" }} />
          </Button>

          {/* <Button onClick={() => handleAddToCart(product.id)} className="btn-sm btn-height-6 main-btn dark-btn-light rounded-full btn-hover" type="button">+</Button> */}


          {cartItem && cartItem.quantity > 0 && (
            <Fragment>
              <Typography
                variant="p"
                fontWeight={600}
                color="primary"
                sx={{ minWidth: 20, textAlign: "center" }}
              >
                {cartItem.quantity}
              </Typography>

              {/* <Button onClick={() => dispatch(removeFromCart(product.id))} className="btn-sm btn-height-6 main-btn dark-btn-light rounded-full btn-hover" type="button">-</Button> */}

              <Button fullWidth variant="contained" onClick={() => dispatch(removeFromCart(product.id))}>
                <Remove sx={{ fontSize: 14, color: "#fff" }} />
              </Button>
            </Fragment>
          )}

        </FlexBetween>
      </Box>
    </div >
  );
};

export default ProductCard;
