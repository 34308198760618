import { Avatar, Badge, Box, Stack, Typography, Button } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FlexBetween } from "../styles";

const BottomSheet = ({ show, cart }) => {
  const navigate = useNavigate();

  const totalAmount = cart.reduce((prev, curr) => prev + curr.quantity * curr.price, 0);

  return (
    <Box
      sx={{
        padding: 2,
        height: 120,
        width: "100%",
        position: "fixed",
        bottom: show ? 0 : -120,
        backgroundColor: "#eee",
        transition: "bottom 0.4s ease",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stack direction="row" spacing={2} mt={1}>
        {cart.map((item) => (
          <Badge key={item.id} badgeContent={item.qty} overlap="circular" color="secondary">
            <Avatar src={item.image} sx={{ backgroundColor: "secondary.200" }} />
          </Badge>
        ))}
      </Stack>

      <FlexBetween mt={1}>
        <Typography variant="h6" fontSize={14} color="black">
          Total: {totalAmount.toFixed(2)} TK
        </Typography>

        <ul style={{ position: 'relative', top: -30, padding: 0, listStyle: "none", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <li style={{ marginBottom: 10 }}>
            <Link to="/checkout" className="main-btn btn-sm success-btn rounded-full btn-hover">Proceed to payment</Link>
          </li>
        </ul>

      </FlexBetween>
    </Box >
  );
};

export default BottomSheet;
