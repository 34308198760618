import React, { useState } from 'react';
import Edit from "./actions/Edit";
import Delete from "./actions/Delete";
import AppModal from "app/components/utils/AppModal";
import { Text, Button } from 'app/components/utils/columnElements';
import * as set from 'app/components/utils/ColumnData';

const ProductCategoryTableContent = ({ item }) => {

    const [modalData, setModalData] = useState(null);

    const handleEdit = () => {
        setModalData({
            title: 'Edit category',
            body: Edit,
            data: item
        });
    };

    const handleDelete = () => {
        setModalData({
            title: 'Delete category',
            body: Delete,
            data: item
        });
    };

    const closeModal = () => {
        setModalData(null);
    };

    return (
        <React.Fragment>
            <tr>
                <td>
                    <div>
                        <Text data={set.text(item.name)} />
                    </div>
                </td>
                <td>
                    <div className="action d-flex justify-content-end">
                        <Button data={set.button(handleEdit, null, 'lni lni-pencil-alt fs-14')} />
                        <Button data={set.button(handleDelete, null, 'lni lni-trash-can text-danger fs-14')} />
                    </div>
                </td>
            </tr>
            {modalData && (
                <AppModal modalData={modalData} modal={true} hideModal={closeModal} />
            )}
        </React.Fragment>
    );
};

export default ProductCategoryTableContent;
