import { persistor } from 'app/store';
import { payment } from 'features/CustomerPaymentSlice';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { STATUS, imageFromSource } from 'services/CommonService';


const PaymentScreen = () => {

    const { price } = useParams();

    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { status } = useSelector((state) => state.customerPayment)

    const { data: machine } = useSelector((state) => state.customer)

    const machineUrl = `/${machine?.machine_type?.name.toLowerCase()}/${machine.id}`

    const [isPaymentChecked, setIsPaymentChecked] = useState(false);

    useEffect(() => {
        const delayedCheckPaymentStatus = setTimeout(async () => {
            const data = { price };
            await dispatch(payment(data));
            setIsPaymentChecked(true);
        }, 5000); // Delay of 15,000 milliseconds (15 seconds)

        return () => clearTimeout(delayedCheckPaymentStatus); // Clear the timeout on cleanup
    }, [price, dispatch]);

    useEffect(() => {
        if (isPaymentChecked) {
            if (status === STATUS.ERROR) {
                persistor.purge();
                navigate(machineUrl);
            }

            if (status === STATUS.IDLE) {
                navigate('/complete');
            }
        }
    }, [status, isPaymentChecked, machineUrl, navigate]);




    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                <div className="card card-style-2 shadow-none" style={{ maxWidth: '30rem' }}>
                    <div className="d-flex justify-content-center align-items-center p-4">
                        <div>
                            <h1 className='fw-bold'>Pay: {price} Tk</h1>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center" style={{ height: 'calc(100vh - 8rem)' }}>
                        <img
                            src={imageFromSource('bkash_qr_code/BKASHBS66423.jpg')}
                            className="card-img-top img-fluid"
                            alt=""
                            style={{ maxHeight: '100%', maxWidth: '100%', objectFit: 'contain' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentScreen;
