// Edit.js

import { useDispatch, useSelector } from "react-redux";
import { UpdateFormValidationRules } from "../validation";
import { update as updateProduct } from "features/ProductSlice";
import { fetch as fetchCategory } from "features/ProductCategorySlice";
import { useEffect } from "react";
import ProductForm from "./ProductForm";
import { processDataForUpdate } from "services/CommonService";


const Edit = (props) => {

    const dispatch = useDispatch();

    const { handleClose, modalData } = props;

    const { data: categories } = useSelector((state) => state.productCategory);

    useEffect(() => {
        dispatch(fetchCategory());
    }, [dispatch]);

    const { id, name, category, image, brand } = modalData.data;

    const initialValues = { name, product_category_id: category?.id, image, brand };

    const onSubmit = (values, onSubmitProps) => {

        const updatedValues = processDataForUpdate(values, id, image)

        dispatch(updateProduct(updatedValues)) && onSubmitProps.resetForm();

        handleClose();
    };

    const nameProps = {
        name: 'name',
        type: 'text',
        placeholder: 'Choose product name..',
    };

    const brandProps = {
        name: 'brand',
        type: 'text',
        placeholder: 'Choose brand name..',
    };

    const categoryProps = {
        name: 'product_category_id',
        placeholder: 'Choose product category..',
        filterBy: category,
        optionFields: categories,
    };

    const imageProps = {
        name: 'image',
        placeholder: 'Choose product image..',
        type: 'file'
    };

    return (
        <ProductForm
            initialValues={initialValues}
            validationSchema={UpdateFormValidationRules}
            onSubmit={onSubmit}
            handleCancel={handleClose}
            categoryProps={categoryProps}
            nameProps={nameProps}
            brandProps={brandProps}
            imageProps={imageProps}
            buttonTitle="Update Product"
            action="update"
        />
    );
};

export default Edit;
